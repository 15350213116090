import { Avatar, List } from "antd";
import React from "react";
import moment from "moment";
import "./activities.less";
import { showChecklisytPreview } from "../../../MyTask/MyTask.Slice";
import { checklistDataGet } from "../../Taskmanagement.Action";
import { useDispatch } from "react-redux";
import {
  getStaffdetiails,
  getVendordetiails,
} from "../../Taskmanagement.Action";
const Activities = (props) => {
  const dispatch = useDispatch();
  const {
    dataSource = [],
    intl: { formatMessage },
    taskMgmtStatus,
    taskTicketDetails,
  } = props;
  const showUserName = (item) => {
    return `
        ${item?.firstName + " " + item?.lastName || item?.custom_fullname}`;
  };
  const showTicketStatus = (status) => {
    if (Boolean(status)) {
      return (
        taskMgmtStatus[status] && (
          <div style={{ color: taskMgmtStatus[status].color }}>
            {taskMgmtStatus[status].name}
          </div>
        )
      );
    }
    return "-";
  };
  return (
    <div>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item) => (
          <>
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    className="task-activity-company-avtar"
                    onClick={() => {
                      if (item.staffData) {
                        const data = {
                          userId: item.staffData._id,
                        };
                        dispatch(getStaffdetiails(data));
                      } else {
                        const data = {
                          vendorId: item.vendorId,
                        };
                        dispatch(getVendordetiails(data));
                      }
                    }}
                    style={{ backgroundColor: "#F1F6F9" }}
                  >
                    {" "}
                    <span className="short-name">
                      {item.staffData
                        ? item?.staffData?.firstName?.charAt(0)
                        : item.vendorData
                        ? item?.vendorData?.firstName?.charAt(0)
                        : "v"}
                      {item.staffData
                        ? item?.staffData?.lastName?.charAt(0)
                        : item.vendorData
                        ? item?.vendorData?.lastName?.charAt(0)
                        : "v"}
                    </span>
                  </Avatar>
                }
                title={
                  <span className="activities-title-task">
                    {showUserName(item?.staffData || item?.vendorData)}&nbsp;{item?.staffData?"(Company)":"(Vendor)"}
                  </span>
                }
                description={
                  <span className="activities-description-task">
                    <div>{item?.title}</div>
                    <div className="task-activity-company-taskstatus">
                      {" "}
                      {item?.taskStatus
                        ? showTicketStatus(item?.taskStatus)
                        : "---"}
                    </div>
                    {/* {item.isCheckList ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const data = {
                            vendorId: item.vendorId || "",
                            taskId: taskTicketDetails._id,
                            userId:
                              item.staffData &&
                              item.staffData._id &&
                              item.staffData._id,
                          };
                          dispatch(checklistDataGet(data));
                          dispatch(
                            showChecklisytPreview({
                              visible: true,
                              record: item,
                            })
                          );
                        }}
                      >
                        View Checklist
                      </div>
                    ) : (
                      ""
                    )} */}
                  </span>
                }
              />
              <div style={{ marginTop: "20px" }}>
                <span
                  className="comment-date TEST"
                  style={{
                    float: "right",
                    fontSize: "12px",
                    color: "grey",
                  }}
                >
                  {moment(item?.created_at || item?.createdAt).calendar(null, {
                    sameDay: `[${formatMessage({
                      id: "today",
                    })}] hh:mm A`,
                    nextDay: `[${formatMessage({
                      id: "tomorrow",
                    })}] hh:mm A`,
                    lastDay: `[${formatMessage({
                      id: "yesterday",
                    })}] hh:mm A`,
                    lastWeek: "DD/MM/YYYY hh:mm A",
                    sameElse: "DD/MM/YYYY hh:mm A",
                  })}
                </span>
              </div>
            </List.Item>
          </>
        )}
      />
    </div>
  );
};

export default Activities;
