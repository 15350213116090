import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  setPasswordFunc,
  getChatRoomApi,
  Forgetuserpassword,
  Setdefaultvendor,
  getRoleData,
  verifyOtp,
  getOtp,
  GuestgetChatRoomApi,
  checkUrlTokenValidity,
  SetChangePassword,
  contactSalesTeam
} from "./userActions";
import { CREATER, REVIEWER, APPROVAL, SUPER_ADMIN } from "../../utils/role";
import moment from "moment";

import {
  DASHBOARD_DEPT_ID,
  USER_DEPT_ID,
  TEMPLATE_DEPT_ID,
  CONTENT_DEPT_ID,
  WORKFLOW_DEPT_ID,
  STAFF_DEPT_ID,
  COMPANY_DEPT_ID,
  VENDOR_DEPT_ID,
  // AGGREEMENT_MGMNT_DEPT_ID,
  TASK_MGMNT_DEPT_ID,
  TASK_MGMNT_VENDOR_DEPT_ID,
  MY_CONTRACT_DEPT_ID,
  // KYC_DEPT_ID,
  MY_TASK,
  OTHER_VENDOR_DEPT_ID,
  HISTORICAL_DEPT_ID,
  LICENCE_DEPT_ID,
  OBLIGATION,
  MYOBLIGATION_DEPT_ID,
  OTHER_DEPT_ID,
  NOTIFICATIONS_DEPT_ID
} from "../../utils/department";
// import UserServices from "./../../Services/userServices";
const userState = {
  forgetpasswordmodal: false,
  isforgetsuccess: false,
  forgetloading: false,
  isAuthenticated: false,
  isresetpasswordsuccessfull: false,
  company_details: [],
  isRemembered: false,
  user_Detail: {},
  userdetails: {},
  vendorDetailsData: {},
  user_cust_emp_id: "",
  isLoading: false,
  isSetpasswordLoading: false,
  companydetails: "",
  currentuser: "",
  vendordetail: [],
  user_dept_access: [
    DASHBOARD_DEPT_ID,
    USER_DEPT_ID,
    TEMPLATE_DEPT_ID,
    CONTENT_DEPT_ID,
    WORKFLOW_DEPT_ID,
    STAFF_DEPT_ID,
    COMPANY_DEPT_ID,
    // OTHER_DEPT_ID,
    LICENCE_DEPT_ID,
    NOTIFICATIONS_DEPT_ID
    // HISTORICAL_DEPT_ID,
    // AGGREEMENT_MGMNT_DEPT_ID,
    // TASK_MGMNT_DEPT_ID,
    // VENDOR_DEPT_ID
  ],
  company_dept_access: [
    DASHBOARD_DEPT_ID,
    USER_DEPT_ID,
    // TEMPLATE_DEPT_ID,
    CONTENT_DEPT_ID,
    WORKFLOW_DEPT_ID,
    STAFF_DEPT_ID,
    // COMPANY_DEPT_ID,
    VENDOR_DEPT_ID,
    TASK_MGMNT_DEPT_ID,
    MY_TASK,
    MY_CONTRACT_DEPT_ID,
    OTHER_VENDOR_DEPT_ID,
    HISTORICAL_DEPT_ID,
    OBLIGATION,
    MYOBLIGATION_DEPT_ID,
    NOTIFICATIONS_DEPT_ID
    // AGGREEMENT_MGMNT_DEPT_ID,
  ],
  REVIEWER_dept_ids: [USER_DEPT_ID, MY_TASK, DASHBOARD_DEPT_ID,NOTIFICATIONS_DEPT_ID],
  vendor_dept_id: [
    DASHBOARD_DEPT_ID,
    USER_DEPT_ID,
    TASK_MGMNT_VENDOR_DEPT_ID,
    MYOBLIGATION_DEPT_ID,
    // KYC_DEPT_ID,
    MY_CONTRACT_DEPT_ID,
    NOTIFICATIONS_DEPT_ID
  ],
  department_ids: [],
  company_ids: [],
  vendor_details: {},
  access_modules: null,
  roleListData: [],
  verifyOtpLoading: false,
  isAdobeconnected: false,
  isDocusignconnected: false,
  licenseQuotaData: {},
  licenseDetailData: {},
  isValiditychecking: false,
  isresetPasswordLinkValid:false,
  iscontactSalesLoading:false,
  iscontactSalesDone:false
};
export const UserSlice = createSlice({
  name: "user",
  initialState: userState,
  reducers: {
    login: (state, action) => {
      // state.user = action.payload;
      // state.isAuthenticated = true;
      // let reqObj = {
      //   email: action.payload.username,
      //   password: action.payload.password,
      // };
      // let res = await UserServices.login(reqObj);
      // state.isRemembered = true;
      // localStorage.setItem("customerToken", "nduiasiudfuia");
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.company_details = [];
      state.isRemembered = false;
      state.user_Detail = {};
      state.userdetails = {};
      state.vendorDetailsData = {};
      state.user_cust_emp_id = "";
      state.isLoading = false;
      state.isSetpasswordLoading = false;
      state.companydetails = "";
      state.currentuser = "";
      state.vendordetail = [];
      localStorage.setItem("customerToken", "");
      localStorage.setItem("taskId", "");
      localStorage.setItem("companyId", "");
      localStorage.setItem("encryptedData", "");
      localStorage.setItem("otp", "");
      localStorage.setItem("vendorId", "");
    
      localStorage.removeItem("prepath");
      localStorage.clear();
    },
    SetforgetpasswordModal: (state, { payload }) => {
    
      state.forgetpasswordmodal = payload.visible;
    },
    setCompines: (state, { payload }) => {
      state.company_ids = payload.companies;
    },
    setRoute: (state, { payload }) => {
      if (
        payload &&
        !payload.pathname.includes("/login") &&
        !localStorage.getItem("dynamic_url_path")
      ) {
        localStorage.setItem("dynamic_url_path", payload.pathname);
      }
    },
    updateVendorData: (state, { payload }) => {
      const abcd = [...state.vendorDetailsData];
      abcd[0].is_require_fill = false;
      state.vendorDetailsData = abcd;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.department_ids = [];
        localStorage.removeItem("customer");
        localStorage.removeItem("companyId");
        localStorage.removeItem("company_ids");
        localStorage.removeItem("customerToken");
        localStorage.removeItem("vendorToken");
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        // state.userdetails = payload.data.user;

        state.company_details = payload.data.companies;
        if (payload.data && payload.data.user && payload.data.user.companyId) {
          state.companydetails = payload.data.user.companyId;
        }
        localStorage.setItem("customer", payload.data.user);
        if (payload.data && payload.data.user && payload.data.user.companyId) {
          localStorage.setItem("companyId", payload.data.user.companyId._id);
          // state.department_ids = [...state.company_dept_access, 999];
        } else if (
          payload?.data &&
          payload?.data?.vendor &&
          payload?.data?.vendor?._id
        ) {
          state.department_ids = [...state.vendor_dept_id, 999];
        } else {
          state.department_ids = [...state.user_dept_access, 999];
        }
        state.isLoading = false;
        state.isAuthenticated = true;
        // localStorage.setItem("rememberMe", remember);
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.isLoading = false;
      })

      .addCase(getChatRoomApi.pending, (state) => {
        state.vendor_details = {};
        state.isSetpasswordLoading = true;
      })
      .addCase(getChatRoomApi.fulfilled, (state, { payload }) => {
        state.userdetails = payload.data.user;
        // state.user_cust_emp_id = payload.data.user._id || "";
        state.isSetpasswordLoading = false;
        state.access_modules = 101;
        if (
          payload.data &&
          payload.data.user &&
          payload.data.user.role &&
          payload.data.user.role.accessCode &&
          [0, SUPER_ADMIN].includes(payload.data.user.role.accessCode) &&
          !payload.data.user.role.isCompany
        ) {
          state.department_ids = [
            ...state.user_dept_access,
            // ...state.company_dept_access,
            999,
          ];
          state.currentuser = "User";
          state.isAdobeconnected = payload.data?.isAdobeConnected || false;
          state.isDocusignconnected =
            payload.data?.isDocuSignConnected || false;

          state.user_Detail = payload.data.user;
          // state.companydetails = payload.data.user.companyId;
        } else if (
          payload.data &&
          payload.data.user &&
          payload.data.user.role &&
          payload.data.user.role.isCompany
        ) {
          if (payload.data.user.role.accessCode) {
            state.access_modules = payload.data.user.role.accessCode;
          }
          // localStorage.setItem("companyId", payload.data.user.companyId);
          state.department_ids = [REVIEWER].includes(state.access_modules)
            ? [...state.REVIEWER_dept_ids, 999]
            : [APPROVAL].includes(state.access_modules)
            ? [
                MY_TASK,
                DASHBOARD_DEPT_ID,
                MY_CONTRACT_DEPT_ID,
                USER_DEPT_ID,
                MYOBLIGATION_DEPT_ID,
                NOTIFICATIONS_DEPT_ID,
                999,
              ]
            : [CREATER].includes(state.access_modules)
            ? [...state.company_dept_access, 999].filter(
                (eve) => ![MY_TASK, OTHER_VENDOR_DEPT_ID].includes(eve)
              )
            : [...state.company_dept_access, 999];
          state.currentuser = "company";
          state.user_Detail = payload.data.user;
          state.licenseDetailData = payload.data.licenseDetail;
          state.licenseQuotaData = payload?.data?.licenseQuota;
           state.isFreeTrialEnded=moment(payload.data.user.companyId?.licenseEndDate).isBefore(moment(), 'day')?true:false
          state.companydetails = payload.data.user.companyId;
        } else if (
          payload.data &&
          payload.data.vendorData &&
          payload.data.vendorData._id
        ) {
          state.department_ids = [...state.vendor_dept_id, 999];
          state.company_details = payload.data.companies;
          state.vendor_details = payload.data.vendorData;
          if (payload.data.companies) {
            const idArray = payload.data.companies.map((obj) => obj.companyId);
            state.company_ids = idArray;
            localStorage.setItem("company_ids", idArray);
          }
          state.vendorDetailsData = payload.data.vendorDetailData;
          state.currentuser = "vendor";
          state.user_Detail = {
            ...payload.data.vendorDetailData[0],
            email: payload.data.vendorData.vendorEmail,
          };
          state.user_Detail = {
            ...payload.data.vendorDetailData[0],
            email: payload.data.vendorData.vendorEmail,
          };
          state.vendordetail = payload.data.vendorDetailData;
        } else {
          state.department_ids = [REVIEWER].includes(state.access_modules)
            ? [...state.REVIEWER_dept_ids, 999]
            : [APPROVAL].includes(state.access_modules)
            ? [
                MY_TASK,
                DASHBOARD_DEPT_ID,
                MY_CONTRACT_DEPT_ID,
                USER_DEPT_ID,
                999,
              ]
            : [CREATER].includes(state.access_modules)
            ? [...state.user_dept_access, 999].filter(
                (eve) => ![MY_TASK].includes(eve)
              )
            : [...state.user_dept_access, 999];
          // state.department_ids = [...state.user_dept_access, 999];
          state.currentuser = "admin";
          state.user_Detail = payload.data.user;
        }
      })
      .addCase(getChatRoomApi.rejected, (state, { payload }) => {
        state.user = null;
        state.isAuthenticated = false;
        localStorage.setItem("customerToken", "");
        state.isSetpasswordLoading = false;
      })
      .addCase(Forgetuserpassword.pending, (state) => {
        state.forgetloading = true;
      })
      .addCase(Forgetuserpassword.fulfilled, (state, { payload }) => {
        state.forgetloading = false;
        state.isforgetsuccess = true;
        state.forgetpasswordmodal = false;
      })
      .addCase(Forgetuserpassword.rejected, (state, { payload }) => {
        state.forgetloading = false;
      })
      .addCase(setPasswordFunc.pending, (state) => {
        state.isSetpasswordLoading = true;
        state.isresetpasswordsuccessfull = false;
      })
      .addCase(setPasswordFunc.fulfilled, (state, { payload }) => {
        state.isSetpasswordLoading = false;
        state.isresetpasswordsuccessfull = true;
      })
      .addCase(setPasswordFunc.rejected, (state, { payload }) => {
        state.isSetpasswordLoading = false;
        state.isresetpasswordsuccessfull = true;
      })
      .addCase(Setdefaultvendor.pending, (state) => {})
      .addCase(Setdefaultvendor.fulfilled, (state, { payload }) => {
        state.user_Detail = { ...payload.data, email: state.user_Detail.email };
      })
      .addCase(Setdefaultvendor.rejected, (state, { payload }) => {})
      .addCase(getRoleData.pending, (state) => {})
      .addCase(getRoleData.fulfilled, (state, { payload }) => {
        state.roleListData = payload.data;
      })
      .addCase(getRoleData.rejected, (state, { payload }) => {})

      .addCase(getOtp.pending, (state) => {})
      .addCase(getOtp.fulfilled, (state, { payload }) => {
        // state.roleListData = payload.data;
      })
      .addCase(getOtp.rejected, (state, { payload }) => {})

      .addCase(verifyOtp.pending, (state) => {
        state.verifyOtpLoading = false;
      })
      .addCase(verifyOtp.fulfilled, (state, { payload }) => {
       
        state.verifyOtpLoading = true;
        state.access_modules = 101;
        if (payload.data && payload.data.vendor && payload.data.vendor._id) {
          state.department_ids = [...state.vendor_dept_id, 999];
          state.company_details = payload.data.companies;
          state.vendor_details = payload.data.vendor;
          if (payload.data.companies) {
            const idArray = payload.data.companies.map((obj) => obj.companyId);
            state.company_ids = idArray;
            localStorage.setItem("company_ids", idArray);
          }
          state.vendorDetailsData = [payload.data.vendorDetailData];
          state.currentuser = "vendor";
          state.user_Detail = {
            ...payload.data.vendorDetailData,
            email: payload.data.vendor.vendorEmail,
          };
          state.user_Detail = {
            ...payload.data.vendorDetailData,
            email: payload.data.vendor.vendorEmail,
          };
          state.vendordetail = payload.data.vendorDetailData;
        }
      })
      .addCase(verifyOtp.rejected, (state, { payload }) => {
        state.verifyOtpLoading = false;
      })
      .addCase(GuestgetChatRoomApi.pending, (state) => {
        state.vendor_details = {};
        state.isSetpasswordLoading = true;
      })
      .addCase(GuestgetChatRoomApi.fulfilled, (state, { payload }) => {
        state.userdetails = payload.data.user;
        // state.user_cust_emp_id = payload.data.user._id || "";
        state.isSetpasswordLoading = false;
        state.access_modules = 101;
        if (
          payload.data &&
          payload.data.user &&
          payload.data.user.role &&
          payload.data.user.role.accessCode &&
          [0, SUPER_ADMIN].includes(payload.data.user.role.accessCode) &&
          !payload.data.user.role.isCompany
        ) {
          state.department_ids = [
            ...state.user_dept_access,
            // ...state.company_dept_access,
            999,
          ];
          state.currentuser = "User";
          state.isAdobeconnected = payload.data?.isAdobeConnected || false;
          state.isDocusignconnected =
            payload.data?.isDocuSignConnected || false;

          state.user_Detail = payload.data.user;
          // state.companydetails = payload.data.user.companyId;
        } else if (
          payload.data &&
          payload.data.user &&
          payload.data.user.role &&
          payload.data.user.role.isCompany
        ) {
          if (payload.data.user.role.accessCode) {
            state.access_modules = payload.data.user.role.accessCode;
          }
          // localStorage.setItem("companyId", payload.data.user.companyId);
          state.department_ids = [REVIEWER].includes(state.access_modules)
            ? [...state.REVIEWER_dept_ids, 999]
            : [APPROVAL].includes(state.access_modules)
            ? [
                MY_TASK,
                DASHBOARD_DEPT_ID,
                MY_CONTRACT_DEPT_ID,
                USER_DEPT_ID,
                MYOBLIGATION_DEPT_ID,
                999,
              ]
            : [CREATER].includes(state.access_modules)
            ? [...state.company_dept_access, 999].filter(
                (eve) => ![MY_TASK].includes(eve)
              )
            : [...state.company_dept_access, 999];
          state.currentuser = "company";
          state.user_Detail = payload.data.user;
          state.licenseDetailData = payload.data.licenseDetail;
          state.licenseQuotaData = payload?.data?.licenseQuota;

          state.companydetails = payload.data.user.companyId;
        } else if (
          payload.data &&
          payload.data.vendorData &&
          payload.data.vendorData._id
        ) {
          state.department_ids = [...state.vendor_dept_id, 999];
          state.company_details = payload.data.companies;
          state.vendor_details = payload.data.vendorData;
          if (payload.data.companies) {
            const idArray = payload.data.companies.map((obj) => obj.companyId);
            state.company_ids = idArray;
            localStorage.setItem("company_ids", idArray);
          }
          state.vendorDetailsData = payload.data.vendorDetailData;
          state.currentuser = "vendor";
          state.user_Detail = {
            ...payload.data.vendorDetailData[0],
            email: payload.data.vendorData.vendorEmail,
          };
          state.user_Detail = {
            ...payload.data.vendorDetailData[0],
            email: payload.data.vendorData.vendorEmail,
          };
          state.vendordetail = payload.data.vendorDetailData;
        } else {
          state.department_ids = [REVIEWER].includes(state.access_modules)
            ? [...state.REVIEWER_dept_ids, 999]
            : [APPROVAL].includes(state.access_modules)
            ? [
                MY_TASK,
                DASHBOARD_DEPT_ID,
                MY_CONTRACT_DEPT_ID,
                USER_DEPT_ID,
                999,
              ]
            : [CREATER].includes(state.access_modules)
            ? [...state.user_dept_access, 999].filter(
                (eve) => ![MY_TASK].includes(eve)
              )
            : [...state.user_dept_access, 999];
          // state.department_ids = [...state.user_dept_access, 999];
          state.currentuser = "admin";
          state.user_Detail = payload.data.user;
        }
      })
      .addCase(GuestgetChatRoomApi.rejected, (state, { payload }) => {
        state.user = null;
        state.isAuthenticated = false;
        localStorage.setItem("customerToken", "");
        state.isSetpasswordLoading = false;
      })
      .addCase(checkUrlTokenValidity.pending, (state) => {
        state.isValiditychecking = true;
      })
      .addCase(checkUrlTokenValidity.fulfilled, (state, { payload }) => {
        state.isValiditychecking = false;
        state.isresetPasswordLinkValid=payload?.STATUS_CODE===401?false:true
      })
      .addCase(checkUrlTokenValidity.rejected, (state, { payload }) => {
        state.isValiditychecking = false;
      })
      .addCase(SetChangePassword.pending, (state) => {
        state.isSetpasswordLoading = true;
        state.isresetpasswordsuccessfull = false;
      })
      .addCase(SetChangePassword.fulfilled, (state, { payload }) => {
        state.isSetpasswordLoading = false;
        state.isresetpasswordsuccessfull = true;
      })
      .addCase(SetChangePassword.rejected, (state, { payload }) => {
        state.isSetpasswordLoading = false;
        state.isresetpasswordsuccessfull = true;
      })
      .addCase(contactSalesTeam.pending, (state) => {
        state.iscontactSalesLoading = true;
      
       
      })
      .addCase(contactSalesTeam.fulfilled, (state, { payload }) => {
       
        state.iscontactSalesLoading = false;
        state.iscontactSalesDone = true;
      
      })
      .addCase(contactSalesTeam.rejected, (state, { payload }) => {
        state.iscontactSalesLoading = false;
        
      
      })
  },
});

export const {
  login,
  logout,
  SetforgetpasswordModal,
  setCompines,
  setRoute,
  updateVendorData,
} = UserSlice.actions;
// export const selectUser = (state) => state.user.isAuthenticated;
export default UserSlice.reducer;
