// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dashboard: "Dashboard",
  templates: "Templates",
  workflow: "Workflow",
  content: "Content",
  company: "Company",
  Communication: "Communication",
  Staff: "Staff",
  created_at: "Created Date",
  Checklist: "Checklist",
  Settings: "Settings",
  activity_log: "Activity Log",
  name: "Name",
  staff_mang: "Staff management",
  Edit_btn: "Edit Button",
  create_staff: "Create Staff",
  update_staff: " Edit Staff",
  delete: "Delete",
  edit: "Edit",
  create: "Create",
  update: "Update",
  cancel: "Cancel",
  role: "Role",
  plce_role: "Please enter role ",
  role_rule: "Please enter role ",
  action: "Action",
  phone_no: "Phone",
  phone_rule: "Please enter a valid phone number",
  plce_phoneno: "Enter Phone ",
  email: "Email",
  plce_email: " Enter email",
  email_rule: "Please enter email",
  lastname: "Last Name",
  lname_rule: "Please enter last name ",
  plce_lastname: "Enter last name",
  plce_firstname: "Enter first name",
  fname_rule: "Please enter first name ",
  firstname: "First Name",
  Create_temp: "Create template",
  update_temp: "Update template",
  active: "Active:",
  submit: "Submit",
  approvars: "Approvars",
  addnew: "Add new",
  view: "View",
  title: "Title",
  single: "Single",
  multiple: "Multiple",
  inp_type: "Input type",
  grid_type: "Grid type",
  selec_type: "Select type of view",
  placeholder: "Placeholder",
  plce_grid: "Select your grid type",
  plce_type: "Select your input type",
  plce_title: " Enter title",
  ent_plce: "Enter placeholder",
  rule_grid: "Please select grid type!",
  rule_type: "Please select template type!",
  rule_title: "Please input your title",
  options: "Options",
  Add_field: "Add field",
  options_rule: "Please input options's name or delete this field.",
  option_name: "Enter option name",
  val_type: "Type of value",
  ent_info: "Enter info",
  ent_label: "Enter label",
  ent_field: "Enter field name",
  info: "Info",
  required: "Required",
  add_attribute: "Add attribute",
  create_workflow: "Create workflow",
  workflow_name: "Name",
  workflow_type: "Type",
  description: "Description",
  checklist: "Checklist",
  company_rule: "Please! enter a company name ",
  select: "Select",
  plce_company: "Select a company name",
  desc_rule: "Please enter a description ",
  workflow_type_rule: "Please select workflow type",
  workflow_rule: "Select a workflow type",
  plce_workflow: "Enter a workflow name",
  plce_workflow_type: "Enter a workflow type",
  plce_checklist: "Select a checklist",
  create_work: "Go to  create workflow",
  con_Content: "Contract content",
  create_content: "Create Content",
  update_content: "Update Content",
  content_name: "Content Name",
  content_type: "Content Type",
  plce_content_type: "Enter type",
  content_type_rule: "Please enter a content type",
  content_name_rule: "Please enter a content name",
  plce_content_name: "Enter name",
  content_rule: "Please enter a content",
  plce_content: "Enter a content",
  create_company: "Create Company",
  company_name: "Company Name",
  address: "Address",
  pincode: "Zip Code",
  categoryId: "  CategoryId",
  update_company: "Update company",
  cname_rule: "Please enter a company name",
  cname_placeholder: "Enter Company Name",
  address_rule: "Please enter a company address",
  plce_address: "Enter address",
  plce_pincode: "Enter zipcode",
  pincode_rule: "Please enter a zipcode",
  workflow_Services: "Workflow Services",
  template_Services: "Template Services",
  content_Services: "Content Services",
  workflowtype_Services: "WorkflowType services",
  option_rule: "Option required",
  category_name: "Category Name",
  create_category: "Create Category",
  update_category: "Update Category",
  categoryname: "Category name",
  category_rule: "Please! enter a category name",
  rule_description: "Please enter a description",
  plce_description: "Enter a description",
  category: "Category",
  plce_category: "Enter a category",
  create_checklist: "Create checklist",
  update_checklist: "Update checklist",
  checklist_name: "Checklist name",
  contentType: "Content Type",
  contentType_rule: "Please enter a content type.",
  plce_contentType: "Enter a content type",
  create_contentType: "Create Content Type",
  update_contentType: "Update Content Type",
  selecttemp: "Select a template",
  selectcontent: "Select a content",
  close: "Close",
  create_Approval: "Create Approval",
  add_approval: "Add Approval",
  levelname: "Level Name",
  strategy: "Strategy",
  update_workflow: "Update Workflow",
  update_password: "Update Password",
  enter_comment: "Enter Comment",
  Template: "Template",
  template: "Templates",

  levelname_rule: "Please enter  level name",
  plce_levelname: "Enter level name",
  workflow_typename: "Workflowtype Name",
  workflow_type_delete: "Are you sure you want to delete this Workflowtype?",
  yes: "Yes",
  no: "No",
  change: "Change",
  create_workflow_type: "Create Workflow Type",
  update_workflow_type: "Update Workflow Type",
  workflow_type_name: "Workflow type name",
  workflowtype_rule: "Please! enter a workflowtype name",
  plce_workflowtype: "Please enter workflow name",
  TimelineComment: "Timeline/Comment",
  please_select_template: "Please select template",
  please_select_content: "Please select content",
  please_select_checklist: "Please select checklist",
  approval: "Approval",
  profile: "Profile",
  resetpassword: "Reset Password",
  reset: "Reset",
  Userdetails: "User Details",
  updatepassword: "Update Password",
  vendor: "Vendor",
  vendors: "Vendors",
  vendor_mang: "Vendor Management",
  create_vendor: "Create Vendor",
  firstName: "First Name",
  lastName: "Last Name",
  save: "Save",
  phoneno: "Phone",
  gender: "Gender",
  update_vendor: "Update Vendor",
  agrrement_management: "Agreement Management",
  refresh: "Refresh",
  task_management: "Task Management",
  create_task: "Create Task",
  update_task: "Update Task",
  task: "Task",
  taskname: "Task Name",
  startdate: "Start Date",
  enddate: "End Date",
  taskname_rule: "Please enter a task name",
  plce_taskname: "Enter a task name",
  plce_enddate: "Select end date",
  enddate_rule: "Please select end date",
  plce_startdate: "Select start date",
  startdate_rule: "Please Select start date",
  task_details: "Task Details",
  company_delete_title: "Are you sure delete this Company?",
  content_delete_title: "Are you sure you want to delete this Content?",
  contenttype_delete_titte:
    "Are you sure delete you want to this Content type?",
  category_delete_title: "Are you sure delete this Category?",
  vendor_delete_title: "Are you sure you want to delete this Vendor?",
  task_delete_title: "Are you sure you want to delete this task?",
  approval_delete_title: "Are you sure you want to delete this Approval?",
  checklist_delete_titte: "Are you sure you want to delete this Checklist?",
  search_: "Search...",
  new_password: "New Password",
  new_password_rule: "Please Input Your New Password!",
  new_password_plce: "Enter New Password",
  confirm_password_plce: "Confirm Your Password",
  confirm_password_rule: "Please Confirm Your Password!",
  password_not_match: "The Two Passwords That You Entered Do Not Match!",
  confirm_password: "Confirm Password",
  company_details: "Company Details",
  workflow_delete_title: "Are you sure you want to delete this Workflow?",
  staff_delete_title: "Are you sure you want to delete this Staff?",
  staff_delete_title_contract: "Are you sure you want to delete this contract?",
  template_delete_title: "Are you sure you want to delete this Template?",
  WorkflowType: "WorkflowType",
  comments: "Comments",
  detailview: "Detail View",
  task_name: "Task Name",
  status: "Status",
  task_template_name: "Task template name",
  Select_vendor: "Select vendor",
  please_select_vendor: "Please Select Vendor",
  please_select_staff: "Please Select staff",
  forgot_password: "Forget password",
  data_not_found: "Data Not Found",
  new_password_rules: "Password must be of 6-16 characters",
  new_password_patternrules:
    "Your password must have uppercase and lowercase letters, at least one number,at least one special character, and be between 8 and 16 characters long. Please try again.",
  new_password_patternrules_new:
    "Your password must have uppercase and lowercase letters, at least one number,at least one special character, and be between 8 and 16 characters long. Please try again.",
  confirm_password_checkrule: "Passwords do not match, please retype",
  edit_comment: "Edit Comment",
  rule_label: "Please enter a label",
  rule_datatype: "Please select a datatype",
  company_owner_detail: "Company owner details",
  Enter_Comment_here: "Enter comment here",

  pleaseEnterComment: "Please enter comment",
  today: "Today",
  tomorrow: "Tomorrow",
  yesterday: "Yesterday",
  edit_content: "Edit Content",
  edit_template: "Edit template",
  basic_details: "Basic Details",
  fields_details: "Fields Details",
  next: "Next",
  previous: "Previous",
  Accept: "Accept",
  rule_category: "Please! select category",
  login: "Login",
  forget_your_password: "Forgot your password?",
  password: "Password",
  password_rule: "Please input your Password!",
  useremail_rule: "Please input your Useremail!",
  useremail: "Useremail",
  plce_workflow_task: "Select a workflow type",
  noDataFound: "No data found",
  wait: "Wait",
  no_record_found: "No Record Found",
  workflow_management: "workflowMangement",
  logout: "Logout",
  super_admin: "Super Admin",
  step: "Step",
  step_1: "Step 1",
  step_2: "Step 2",
  step_3: "Step 3",
  input_comment_rule: "Please enter comment",
  select_all: "Select all",
  checklist_detail: "Checklist Details",
  content_detail: "Content Details",
  template_detail: "Template Details",
  approval_detail: "Approval Details",
  changepassword: "Change password",
  plce_strategy: "Select strategy",
  please_strategy: "Please select strategy",
  level: "Level",
  reject: "Reject",
  task_managementVendor: "Task Management Vendor",
  selectchecklist: "Select checklist",
  Otp: "OTP",
  otp_rule: "Enter a OTP",
  new_otp_plce: "Enter OTP",
  plce_select_company: "Select company",
  workflow_approve_title: "Approve workflow?",
  approve: "Approve",
  Content_Checklist: "Content Checklist",
  content_selection: "Content Selection",
  workflow_accept_title: "Accept workflow?",
  type: "Type",
  pending: "Pending",
  progress: "Progress",
  completed: "Completed",

  signature_required: "Signature is required",
  checklist_item: "Checklist Item",
  parameterized: "Parameterized",
  assign: "Assign",
  assign_workflow: "Assign workflow",
  mycontract: "My Contracts",
  kyc: "KYC",
  created_by: "Created by",
  download: "Download",
  email_valid_rule: "Enter  valid email",
  comment: "Comment",
  total: "Total",
  created_content: "Created content",
  approve_content: "Approve content",
  created_workflow: "CreatedWorkflow",
  assigned_workflow: "AssignedWorkflow",
  approvedWorkflow: "ApprovedWorkflow",
  inprogress: "Inprogress",
  pendingtasks: "Pending task",
  total_template: "Total template",
  total_content: "Total Content",
  total_workflow: "Total Workflow",
  total_task: "Total task",
  task_detail: "Task details",
  workflow_detail: "Workflow Details",
  content_details: "content details",
  email_must_rule: "Please enter email",
  Template_Fields: "Template Fields",
  clear: "Clear",
  total_company: "Total Companies",
  accepted_content: "Accepted Contents",
  rejection_comment: "Rejection Comment",
  contractexpiry: "Contract expiry",
  rule_contractexpiry: "Please enter contract expiry",
  type_of_input: "Type of input",
  type_your_signature: "Type your Signature",
  default_fields: "Default Fields",
  enterdigit: "Enter digit",
  please_select: "Please select one option",
  default_field: "Default fields",
  key: "Key",
  sample_content: "Sample content",
  create_SampleContent: "Create sample content",
  content_title: "Title",
  update_SampleContent: "Update sample content",
  notification: "Notification",
  plce_select_sample: "Select sample",
  review_comment: "Review comments",
  expire_on: "Expires on",
  my_contracts: "My contracts",
  my_task: "My Task",
  staff: "Staff",
  Select_staff: "Select Staff",
  Activation_date: "Activation Date",
  expire_date: "Expire Date",
  create_new: "Create new",
  workflow_popupconfirm_title: "Are you sure you want to submit the workflow?",
  taskname_contractname: "Name",
  designation: "Designation",
  designation_placeholder: "Enter Designation",
  contract: "Contract",
  create_template: "Create Template",
  create_date: "Created Date",
  filter: "Filters",
  content_no: "Content No",
  template_no: "Template No.",
  workflow_no: "Workflow No.",
  popup: "Popup",
  total_templates: "Template(s)",
  total_contents: "Content(s)",
  total_workflows: "Workflow(s)",
  total_companys: "Company(s)",
  content_types: "Content Type",
  total_tasks: "Task(s)",
  assigned_company: "Assigned Company(ies)",
  fill_vendor_detail: "Fill Vendor Details",
  workflow_reject_title: "Reject workflow",
  Assign_vendors: "Assignee Vendors",
  vendor_details: "Vendor Detail",
  other_Services: "Other Services",
  preference: "Preference",
  companyName: "CompanyName",
  Task_popupconfirm_title: "Are you sure you want to approve?",
  ApprovedTask: "Approved",
  signInProgress: "Signinprogress",
  company_content: "Company's Content",
  open: "Open",
  expired: "Expired",
  enable: "Enable",
  disable: "Disable",
  inactive: "InActive",
  active_confirm_title: "Are you sure want to activate this company?",
  inactive_confirm_title: "Are you sure want to deactivate this company?",
  enable_confirm_title:
    "Are you sure want to activate license of  this company?",
  disable_confirm_title:
    "Are you sure want to deactivate license of this company?",
  task_datasubmit_title: "Is your effective date and execution date the same?",
  renew_task_datasubmit_title:
    "Is your effective date is same as original contract's expiry date?",
  task_datasubmit_execution_title:
    "your Effective date is same as Execution date",
  renew_task_datasubmit_execution_title:
    "your effective date will be original contract's expiry date",
  content_validation:
    "You have not used some fields from the Template. Are you sure you want to submit the Content?",
  task_datasubmit_executionafter_title: "your effective date will be",
  import: "Import",
  create_bulk_task: "Create Bulk Task",
  sample_excel_file: "Sample Excel File",
  file: "File",
  import_SampleContent: "Import Sample content",
  import_contentType: "Import Content type",
  import_category: "Import Category",
  import_workflow_type: "Import Workflowtype",
  import_checklist: "Import Checklist",
  Ok: "Ok",
  enable_licence: "Activate License",
  disable_licence: "Deactivate License",
  activate: "Activate",
  deactivate: "Deactivate",
  start_date: "Start date",
  end_date: "End date",
  effective_date: "Effective date",
  execution_date: "Execution date",
  contract_expiry: "Contract Expiry",
  phone_number: "Phone Number",
  dob: "DoB",
  age: "Age",
  Workflow_key: "Workflow Key",
  effective_execution_date_same: "Effective execution date same",
  level_1: "Level 1",
  task_start_date: "Task start date",
  task_end_date: "Task end date",
  secretAccessKey: "Secret Access Key",
  keyId: "Key Id",
  storageType: "Storage Type",
  Select_Email: "Select Email",
  premium_features: "Premium Features",
  company_premium_features: "Company Premium Features",
  bulk_task: "Bulk Task",
  awsBucketRegion: "AWS Bucket Region",
  awsBucketName: "AWS Bucket Name",
  storagetype_confirm_title:
    "From Now your data will stored in provided bucket",
  tlp_storageType: "Select your storage type",
  tlp_key_id:
    "The AWS Key ID is a unique identifier for accessing your storage in AWS. It can be obtained from your AWS account dashboard.",
  tlp_secretAccessKey:
    "The AWS Secret Access Key is a confidential key used for authentication and access to your AWS storage. Make sure to keep it secure and avoid sharing it.",
  tlp_awsBucketName:
    "The AWS Bucket Name refers to the unique name of your storage bucket in AWS. It is used to identify and access the specific bucket where your data is stored.",
  tlp_awsBucketRegion:
    "The AWS Bucket Region represents the geographical region where your storage bucket is located. It determines the physical location where your data is stored.",
  user_details: "User Details",
  access_key_id: "AccessKeyId",
  upload_request: "Upload request",
  change_request: "Change request",
  update_request: "Update request",
  changes_history: "Changes History",
  template_history: "Template History",
  history: "History",
  upload_request_title: "What do you want to change?",
  content_: "Content",
  changes: "Changes",
  review_changes: "Review changes",
  historical_contract: "Historical Contracts",
  create_historical_contract: "Create Historical Contract",
  update_historical_contract: "Update Historical Contract",
  contract_name: "Contract Name",
  contract_type: "Contract Type",
  parties_name: "Parties Name",
  parties_address: "Party Address",
  expiry_date: "Expiry date",
  form_name: "Form Name",
  form_type: "Extra Form Field Type",
  select_form_type: "Select extra field type",
  plce_contract_name: "Enter contract name",
  plce_parties_name: "Enter Parties name",
  plce_parties_desc: "Enter Parties description",
  plce_form_name: "Enter form name",
  select_contract_type: "Select contract type",
  details: "Details",
  preview: "Preview",
  noDataMsg: "No Data",
  extra_details: "Extra Details",
  comments_activity: "Comment/Activities",
  no_contracts: "No Contracts",
  Inprogress: "Inprogress",
  historical_Services: "Historical Services",
  Template_details: "Template Details",
  activities: "Activities",
  company_signatory: "Company Signatory",
  parties: "Parties",
  abort: "Abort",
  re_open: "Reopen",
  nickName: "Nickname",
  nickName_rule: "Please enter nickname",
  registeredAddress: "Registered Address",
  plce_nickName: "Enter nickname",
  plce_registeredAddress: "Enter registered address",
  registeredAddress_rule: "Please enter register address",
  content_types_contract_type: "Contract Type",
  verify_contract: "Verify Contract",
  relation_list: "Relation List",
  create_relation_list: "Create Relation Form",
  relation_form: "Relation Form",
  nickname_same_as_above_name: "Nickname same as above name ?",
  registerd_address_same_as_above: "Registerd address same as above?",
  terminate: "Terminate",
  addendum: "Addendum",
  create_addendum: "Create addendum",
  fill_template: "Fill template",
  addendumName: "Name",
  renew_contract: "Renew Contract",
  Default_View: "Grid View",
  vendor_view: "Vendor View",
  my_contract: "My contract",
  contract_details: "Contract Details",
  reviewer: "Reviewer",
  vendor_name: "Vendor Name",
  parent_contract_name: "Parent Contract Name",
  review: "Review",
  add_review: "Add Review",
  review_comp: "Complete Review",
  draft: "Draft",
  in_progress: "In Progress",
  userName: "User Name",
  GDD: "Google Drive Details",
  reviewer_name: "Reviewer Name",
  address1: "Address1",
  address2: "Address2",
  country: "Country",
  country_rule: "Select country",
  plce_country: "Please Select country",
  state: "State",
  select_state: "Select state",
  plce_state: "Please Select State",
  city: "City",
  select_city: "Select City",
  plce_city: "Please Select City",
  version: "Version",
  please_select_version: "Please select version",
  Select_version: "Select version",
  version_preview: "Version Preview",
  storageType_rule: "Please Select Storage Type",
  content_req_change: "Content Requested  Changes",
  comment_list: "Comment List",
  timeZone: "Timezone",
  verify: "Verify",
  timeZone_rule: "Please select timezone",
  plce_country_timezone: "Select timezone",
  connect_to: "Connect to",
  reconnect_to: "Connect",
  ok: "Ok",
  reasonArr: "Error",
  confirm_content: "Confirm content",
  address_2: "Address Line2",
  contract_verification: "Do you require contract verification ?",
  contract_count: "Do you require contract count ?",
  setcontract_count: "Enter Contract limit",
  content_count: "Do you require content count ?",
  setcontent_count: "Enter Content limit",
  contentLibrary_count: "Do you require content library count ?",
  setcontentLibrary_count: "Enter Content library limit",
  select_storages: "Select storages",
  select_contract_limit: "Is contract limited ?",
  sethistorical_count: "Enter Historical Contract limit",
  select_historicalcontract_limit: "Is historical contract limited ?",
  hiscontract_count: "Do you require  historical contract count ?",
  staff_count_limit: "Do you require limited reviewers ?",
  template_count_limit: "Do you require limited templates ?",
  workflow_count_limit: "Do you require limited workflows ?",
  vendor_count_limit: "Do you require limited vendors ?",
  signatory_count_limit: "Do you require limited signatories ?",

  settemplate_count_: "Enter Template limit",
  setvendor_count_: "Enter Vendor limit",
  setworkflow_count_: "Enter Workflow limit",
  setsignatory_count_: "Enter Signatory limit",
  select_staffcount_limit: "Is reviewer limited ?",
  setstaff_count_: "Enter Reviewer limit",
  resend_code: "Resend Code",
  otp_required: "OTP is required",
  otp_description:
    "Please enter the verification code which is send on your  email address",
  is_guest_user: "Login as a Guest User",
  License: "Licence",
  licence_name: "Plan name",
  licensing_features: "Licensing features",
  planpackages: "Plans",
  no_list: "No List",
  plans: "My plan",
  termination_date: "Termination date",
  execution_status: "Execution status",
  plce_parties_add: "Client address",
  cp_address: "Counter party address",
  cp_name: "Counter party name",
  cp_detail: "Counter party details",
  your_role: "Your role",
  cp_business_type: "Counter party business type",
  client_name: "Client name",
  client_address: "Client address",
  extra_delete_titte: "Are you sure delete this extra form?",
  guest_user: "Guest User",
  last7Days: "Last 7 Days",
  last30Days: "Last 30 Days",
  currentMonth: "Current Month",
  lastMonth: "Last Month",
  last3Months: "Last 3 Months",
  last6Months: "Last 6 Months",
  Oneyear: "Last 1 Year",
  obligation: "Obligation",
  create_obligation: "Create obligation",
  myobligation: "My obligation",
  step_4: "Step 4",
  assign_to: "Assign to",
  slaType: "SLA",
  update_obligation: "Update obligation",
  adobe: "Adobe",
  signature: "Signature",
  select_signatures: "Select signature",
  obligation_Services: "Obligation Services",
  onbgname_rule: "Please enter obligation name",
  slatype_type_rule: "Please select service level agreement",
  please_select_obligationForm: "Please select obligation form",
  create_obligation_form: "Create obligation form",
  attachment_rule: "Please add attachment",
  accept: "Accept",
  frequency_type_rule: "Select frequency",
  staff_assignee: "Staff Assignee",
  please_add_reject_comment: "Please add comment",
  please_add_reject_comment_empty: "Comment can't be an empty",
  Contractclause: "Contract clause",
  add_rejection_comment: "Add comment",
  obligation_delete_title_workflow:
    "Are you sure you want to delete this obligation?",
  frequency: "Frequency",
  obligation_delete_title: "Are you sure you want to delete this Obligation?",
  obligationform_delete_titte:
    "Are you sure you want to delete this Obligation form?",
  activity: "Activity",
  department: "Department",
  plz_select_dept: "Please select department",
  select_dept: "Select department",
  enter_limit: "Enter Limit",
  phone_limit: "Please enter a valid limit",
  financial_limit: "Financial Limit Sign",
  chat: "Chat",
  plan_delete_title: "Are you sure want to delete this plan?",
  content_lib: "Content Library",
  import_content: "Import Content",
  pick_content_from: "Select Content from Library",
  Docusign: "Docusign",
  Reviewer_title_req: "How many internal reviewers are required ?",
  approval_title_req: "How many signatories are required ?",
  renewalName: "Renewal Name",
  task_id: "TaskId",
  cname_emptyrule: "Company name cannot be an empty string",
  email_emptyrule: "Email cannot be an empty string",
  designation_emptyrule: "Designation cannot be an empty string",
  firstname_emptyrule: "First name cannot be an empty string",
  lastname_emptyrule: "Last name cannot be an empty string",
  registeredAddress_emptyrule: "Address cannot be an empty string",
  nickName_emptyrule: "Nick name cannot be an empty string",
  obligations: "Obligations",
  date: "Date",
  task_no: "Task No",
  plce_select_content_type: "Select type",
  please_content_type_rule: "Please select content type",
  id: "Id",
  contract_no: "Contract No",
  sign_delete_title: "Are you sure want to delete signature?",
  googledrive_delete_confirm_title:
    "Are you sure you want to delete Google Storage? From now on, your contract will be stored in the default storage.",
  clause_lib: "Clause library",
  create_clause: "Create Clause",
  update_clause: "Update Clause",
  clause: "Clause",
  last_modified: "Last Modified",
  plce_clauseDescription: "Describe the type of clause",
  plce_clauseName: "Name the type of clause, e.g. Ammendment,Meditation etc.",
  clause_delete_title: "Are you sure you want to delete this Clause?",
  fields: "Fields",
  clauseDescription_rule: "Please enter or describe the clause",
  clauseName_rule: "Please enter name ",
  forgot_password_: "Forgot password",
  request: "Request(s)",
  signout: "Sign Out",
  Contact_sales: "Contact Sales",
  all: "All",
  unread: "Unread",
  contractType: "Contract Type",
  create_contractType:"Create Contract Type",
  content_template:"Content Templates"
};
