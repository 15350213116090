import { createSlice, nanoid } from "@reduxjs/toolkit";
import {
  getContent,
  createContent,
  deleteContent,
  updateContent,
  getbackendvariables,
  peviewDataGet,
  peviewfromcontentDataGet,
  contentUploadDoc,
  SubmitContentforcreation,
} from "./content.Action";

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  contentTableData: [],
  isVisible: false,
  isContentVisible: false,
  workflownewcontentdata: {},
  editRecord: {},
  type: "",
  staffmDetails: {},
  totalCount: null,
  contentPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  taskDetailsdata: "",
  template_id: "",
  backendvariablesdata: {},
  backendvariablesforcontent: {
    companyVars: [
      {
        title: "Company Name",
        type: "text",
        key: "bd_companyName",
        id: 1,
      },
      {
        title: "Company Registered Address",
        type: "text",
        key: "bd_company_registered_address",
        id: 2,
      },
      {
        title: "Company Nickname",
        type: "text",
        key: "bd_company_nickName",
        id: 3,
      },
      {
        title: "Company Email",
        type: "email",
        key: "bd_company_email",
        id: 4,
      },
    ],
    counterpartyVars: [
      {
        title: "Counterparty Signer Name",
        type: "text",
        key: "bd_vendorName",
        id: 5,
      },
      {
        title: "Counterparty Signer Registered Address",
        type: "text",
        key: "bd_vendor_registered_address",
        id: 6,
      },
      {
        title: "Counterparty Signer Nickname",
        type: "text",
        key: "bd_vendor_nickName",
        id: 7,
      },
      {
        title: "Counterparty Signer Email",
        type: "email",
        key: "bd_vendor_email",
        id: 8,
      },
      {
        title: "Counterparty Company Name",
        type: "text",
        key: "bd_vendor_company",
        id: 9,
      },
    ],
  },

  isContentPreviewVisible: false,
  contentPreviewModaType: "",
  isContetPreviewLoading: false,
  contentPreviewRecord: {},
  editRecordContent: "",
  contentFillData: [],
  previewType: "",

  contentPreviewLoading: false,
  contentPreviewData: "",
  isContentDatainlimit: {},
  isAddFieldsModalVisible: false,
  customAddedFields: [],
  backendJsonRecord: [],
};
const contentSlice = createSlice({
  name: "content",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.taskDetailsdata = payload.taskDetailsdata;
      state.type = payload.type || "";
      state.editRecord = payload.record || {};
      state.customAddedFields = payload?.record?.customFields || [];

      state.editRecordContent = payload.editRecordContent
        ? Array.isArray(payload.editRecordContent) &&
          payload.editRecordContent.length
          ? payload.editRecordContent[0].content
          : payload.editRecordContent.content
        : payload.record
        ? payload.record.content
        : "";
      if (!payload.visible) {
        state.editRecord = {};
        state.taskDetailsdata = {};
        state.contentPreviewData = "";
      }
    },
    onPageChangesFilter: (state, action) => {
      state.contentPagination = {
        ...state.contentPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    setTemplateIds: (state, action) => {
      state.template_id = action.payload.template_id;
    },
    onSearchList: (state, action) => {
      state.contentPagination = {
        ...state.contentPagination,
        search: action.payload.search || "",
      };
    },
    showContentmodal: (state, { payload }) => {
      state.isContentVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    showContentPreviewmodal: (state, { payload }) => {
      console.log(payload);
      state.isContentPreviewVisible = payload.visible;
      state.contentPreviewRecord = payload.record || {};
      state.backendJsonRecord = payload?.record?.backendJson || [];
      state.previewType = payload?.type || "";
      if (payload.record && payload.record.fields) {
        state.contentFillData = [payload.record];
      }
      state.contentPreviewModaType = payload.type || undefined;
      if (!payload.visible) {
        state.contentPreviewRecord = {};
        state.contentFillData = [];
      }
    },
    templateValueonChange: (state, { payload }) => {
      const { parentindex, value, contractType, field_id } = payload;
      const updatedFields = [...state.contentFillData[parentindex].fields];
      let findChildIndex = updatedFields.findIndex(
        (eve) => eve._id === field_id
      );

      if (findChildIndex > -1) {
        updatedFields[findChildIndex] = {
          ...updatedFields[findChildIndex],
          value,
          contractType,
        };
      }

      const updatedTemplatefiltereddata = [...state.contentFillData];
      updatedTemplatefiltereddata[parentindex] = {
        ...updatedTemplatefiltereddata[parentindex],
        fields: updatedFields,
      };
      return {
        ...state,
        // contractExpiry: contractexpiry,
        contentFillData: updatedTemplatefiltereddata,
      };
    },
    Contentdatareset: (state, action) => {
      return initialState;
    },
    setworkflowContentdatareset: (state, action) => {
      state.workflownewcontentdata = {};
    },
    setobligationassignModal: (state, { payload }) => {
      state.obligationassignModalvisible = payload.visible;
    },
    setAddFieldsModalVisible: (state, { payload }) => {
      state.isAddFieldsModalVisible = payload.visible;
    },
    setcustomAddedFields: (state, { payload }) => {
      state.customAddedFields = [
        ...state.customAddedFields,
        { ...payload, _id: nanoid() },
      ];
    },
    setcustomAddedFieldsFromTask: (state, { payload }) => {
      state.customAddedFields = [...payload];
    },
    setresetcustomAddedFields: (state, { payload }) => {
      state.customAddedFields = [];
    },
    setbackendJson: (state, { payload }) => {
      const { value, key, index } = payload;
      console.log(payload);
      // state.backendJson= payload;
      const updatedFields = [...state.backendJsonRecord];
      console.log("updatedFields", updatedFields);
      updatedFields[index] = {
        ...updatedFields[index],
        value,
      };
      return {
        ...state,
        backendJsonRecord: updatedFields,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContent.fulfilled, (state, { payload }) => {
        state.contentTableData = payload.data;
        state.isContentDatainlimit = payload?.licenseQuota?.content;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.contentPagination = {
          ...state.contentPagination,
          total: payload.total,
        };
      })
      .addCase(getContent.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createContent.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createContent.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        state.contentPreviewData = "";

        if (state.type === "createfromworkflow") {
          state.workflownewcontentdata = payload.data;
          state.contentPreviewData = "";
        }
      })
      .addCase(createContent.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updateContent.pending, (state) => {
        state.isCreateLoading = true;
        state.isSuccess = false;
      })
      .addCase(updateContent.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.contentPreviewData = "";

        state.isVisible = false;
      })
      .addCase(updateContent.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(peviewDataGet.pending, (state) => {
        state.isContetPreviewLoading = true;
        state.isSuccess = false;
      })
      .addCase(peviewDataGet.fulfilled, (state, { payload }) => {
        state.isContetPreviewLoading = false;
        state.contentPreviewRecord = payload;
        state.contentPreviewModaType = "preview";
      })
      .addCase(peviewDataGet.rejected, (state, { payload }) => {
        state.isContetPreviewLoading = false;
      })
      .addCase(peviewfromcontentDataGet.pending, (state) => {
        state.isContetPreviewLoading = true;
        state.isSuccess = false;
      })
      .addCase(peviewfromcontentDataGet.fulfilled, (state, { payload }) => {
        state.isContetPreviewLoading = false;
        state.contentPreviewRecord = payload;
        state.contentPreviewModaType = "preview";
      })
      .addCase(peviewfromcontentDataGet.rejected, (state, { payload }) => {
        state.isContetPreviewLoading = false;
      })
      .addCase(deleteContent.pending, (state) => {
        //conditions
      })
      .addCase(deleteContent.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deleteContent.rejected, (state, { payload }) => {
        //conditions
      })
      .addCase(getbackendvariables.pending, (state) => {})
      .addCase(getbackendvariables.fulfilled, (state, { payload }) => {
        state.backendvariablesdata = payload.data;
        state.backendvariablesforcontent = payload?.data?.backendVars;
      })
      .addCase(getbackendvariables.rejected, (state, { payload }) => {})
      //contentUploadDoc
      .addCase(contentUploadDoc.pending, (state) => {
        state.contentPreviewLoading = true;
      })
      .addCase(contentUploadDoc.fulfilled, (state, { payload }) => {
        state.contentPreviewData = payload.data;
        state.contentPreviewLoading = false;
      })
      .addCase(contentUploadDoc.rejected, (state, { payload }) => {
        state.contentPreviewLoading = false;
        state.contentPreviewData = "";
      })
      .addCase(SubmitContentforcreation.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(SubmitContentforcreation.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.editRecord = payload.data;
      })
      .addCase(SubmitContentforcreation.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      });
  },
});
export const {
  setModalVisible,
  onPageChangesFilter,
  onSearchList,
  showContentmodal,
  Contentdatareset,
  setTemplateIds,
  setworkflowContentdatareset,
  showContentPreviewmodal,
  templateValueonChange,
  setobligationassignModal,
  setAddFieldsModalVisible,
  setcustomAddedFields,
  setresetcustomAddedFields,
  setcustomAddedFieldsFromTask,
  setbackendJson,
} = contentSlice.actions;

export default contentSlice.reducer;
