import { createSlice } from "@reduxjs/toolkit";

import {
  getTaskdetails,
  createTask,
  deleteTask,
  getTaskcomments,
  createTaskcomment,
  getDetails,
  createTemplateTask,
  taskAssignee,
  getReviewerStaffList,
  taskConnect,
  getStaffList,
  getVendordetailsList,
  getWorkflowdetailsfortask,
  createbulktask,
  changeforrequestAction,
  getTaskactivitylist,
  createTaskaddendum,
  Taskaddendumstep,
  getaddendumsteplistanddetails,
  getaddendumstepdetails,
  gettaskaddendumstepdetails,
  createTaskRenew,
  getRenewsteplistanddetails,
  TaskRenewstep,
  ReviewerSubmit,
  customActions,
  checklistDataGet,
  getVerionList,
  saveVersion,
  getCommentContent,
  editTaskContentTemplate,
  getReviewerEmailIds,
  sendReviewerInvitaion,
  completeReviewerInvitaion,
  getReviewDetails,
  completeReviewerParties,
  sendMailToParties,
  editTaskContentForNoncollab,
  editTaskTemplateForNoncollab,
  checkEmailregisteredorNot,
  setAbortTask,
  getreOpentask,
  SubmiteditTemplateValues,
  // deleteTaskComment
} from "./Taskmanagement.Action";
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  taskTableData: [],
  isVisible: false,
  ispasswordVisible: false,
  ispasswordloading: false,
  isdrawerVisible: false,
  editRecord: {},
  type: "",
  staffmDetails: {},
  ispagination: false,
  totalCount: null,
  taskPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "updatedAt",
  },
  TaskcommentPagination: {
    limit: "10",
    page: "1",
    orderBy: "createdAt",
    sortBy: "DESC",
  },
  TaskCommentData: [],
  Templatefiltereddata: [],
  Checklistfiltereddata: [],
  currenttaskStep: 0,
  aciveTaskKey: "1",
  commentLoading: false,
  isDataLoading: false,
  taskTicketDetails: {},
  taskMgmtStatus: {
    1: {
      name: "Open",
      color: "#e17b27",
      tagcolor: "green",
      id: 1,
    },
    4: {
      name: "Approval InProgress",
      color: "#01aef0",
      id: 4,
      tagcolor: "processing",
    },

    5: {
      name: "Approved",
      color: "#8ec63f",
      id: 5,
    },
    6: {
      name: "Rejected",
      color: "#800000",
      id: 6,
      tagcolor: "error",
    },
    7: {
      name: "Completed",
      color: "green",
      id: 6,
      tagcolor: "success",
    },
    8: {
      name: "Signature InProgress",
      color: "#077E8C",
      id: 6,
    },
    9: {
      name: "Expired",
      color: "red",
      id: 6,
      tagcolor: "error",
    },
    10: {
      name: "Update Request",
      color: "#5BC0DE",
      id: 7,
      tagcolor: "purple",
    },
    11: {
      name: "Update Accepted",
      color: "green",
      id: 8,
      tagcolor: "green",
    },
    12: {
      name: "Update Rejected",
      color: "red",
      id: 9,
    },
    13: {
      name: "Update Completed",
      color: "#5CB85C",
      id: 10,
    },
    14: {
      name: "Aborted",
      color: "cyan",
      id: 10,
    },
    15: {
      name: "Internal review",
      color: "#01aef0",
      id: 15,
      tagcolor: "processing",
    },
    16: {
      name: "Review Completed",
      color: "green",
      id: 16,
    },
    18: {
      name: "Reviewed",
      color: "#0f8167",
      id: 18,
    },
  },
  isTaskSubmitLoading: false,
  DrawerRecord: "",
  isTaskAssigneeLoading: false,
  isTaskConnectLoading: false,
  taskContentString: "",
  iscoommentmodalVisible: false,
  iscommentSuccess: false,
  iscommentLoading: false,
  editcommentdata: {},
  approvalsData: [],
  collapseDataIds: 0,
  contractExpiry: 0,
  iscommentmodalVisible: false,
  isgetStaffListLoading: false,
  staffLists: [],
  vendorTableData: [],
  isapprovalmodalVisible: false,
  isuserSelectVisible: false,
  userselecteddata: {},
  newcreatedtaskdata: {},
  isbulkcreateVisible: false,
  isbulktaskviewdrawerVisible: false,
  uploadedexceldata: [],
  uploadedexcelheaderdata: [],
  workflowidforbulktask: "",
  workflowData: [],
  forcheckingarraydata: [],
  bulkModaltype: "",
  isbulkEditVisible: false,
  bulkRecord: {},
  bulk_work_flow_id: "",
  isrequestchangeVisible: false,
  ischangeforrequestLoading: false,
  taskactivityData: [],
  iscustomconfirmvisible: false,
  iscustomconfirmdata: {},
  iscreateaddendumvisible: false,
  isaddendumtype: "",
  currentaddendumstep: 0,
  iscreateaddendumdrawervisible: false,
  addendumcreationdata: {},
  taskaddendumcreateddata: {},
  Taskaddendumstepdetails: {},
  Taskaddendumsteplist: [],
  taskaddendumPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  addendumtaskdetailsdata: {},
  oneaddendumtaskdetailsdata: {},
  Addendumfiltereddata: [],
  taskaddendumcreateddataloading: false,
  isaddendumCreating: false,
  isAddendumTableDataLoading: false,

  currentrenewstep: 0,
  iscreaterenewDrawervisible: false,
  iscreateRenewVisible: false,
  isChecklistContentvisible: false,
  isRenewtype: "",
  renewtaskdetailsdata: {},
  oneRenewtaskdetailsdata: {},
  isRenewCreating: false,
  isRenewTableDataLoading: false,
  TaskRenewsteplist: [],
  taskRenewcreateddataloading: false,
  contentTabActivekey: "1",

  isRenewListLoading: false,
  RenewalStaffList: [],

  isReviewEdit: false,
  is_reviewId: null,
  is_reviewCountEdit: false,
  is_guest_user: false,
  reviewerName: "",

  addeddumTemplate_id: [],
  addeddumContent_id: [],

  isChangeRequestDrawerVisible: false,
  taskDetailsdata: "",
  changeReqContentType: "",
  changeReqeditRecord: {},
  editRecordContent: {},
  checlistPreviewData: [],
  checklistDataLoading: false,

  versionList: [],
  versionLoading: false,
  commentReqLoading: false,
  commentReqData: null,

  editContentVisible: false,
  editContentType: "",
  editContentDetail: {},
  editContentLoading: false,
  isContractpreviewPreviewVisible: false,

  isGuestUserModal: false,
  guestUserDetail: {},
  guestUserOpertaion: [
    {
      name: "First Name",
      key: "firstName",
      disabled: true,
    },
    {
      name: "Last Name",
      key: "lastName",
      disabled: true,
    },
    {
      name: "Designation",
      key: "designation",
      disabled: false,
    },
    {
      name: "Email",
      key: "email",
      disabled: true,
    },
    {
      name: "Company",
      key: "company",
      disabled: true,
    },
    {
      name: "Registered Address",
      key: "vendor_registered_address",
      disabled: false,
    },
    {
      name: "Country",
      key: "countryName",
      disabled: true,
    },
    {
      name: "State",
      key: "stateName",
      disabled: true,
    },
    {
      name: "City",
      key: "cityName",
      disabled: true,
    },
    {
      name: "Zip code",
      key: "pinCode",
      disabled: false,
    },
    {
      name: "Phone No",
      key: "phoneNo",
      disabled: false,
    },
    {
      name: "Nick Name",
      key: "vendor_nickName",
      disabled: false,
    },
  ],

  isEmailPopupvisible: false,
  emailRecord: {},
  is_collobrative_mode: false,

  reviewerEmailIds: [],
  reviewerEmailObject: [],
  reviewerEmailLoading: false,

  reviewerInvitationLoading: false,
  completeReviewLoading: false,

  isReviewMorePopupvisible: false,
  reviewMoreRecord: {},

  isObligationDrawerVisible: false,
  obligationRecord: {},

  versionDrawerVisible: false,
  versionRecord: {},

  reviewDetails: {},
  reviewerDetailsLoading: false,

  iscompleteReviewPopup: false,
  reviewRecord: {},
  isNoncollabRequestDrawerVisible: false,
  isVersiondrawerVisible: false,
  isversionsaving: false,
  isviewObligationdrawerVisible: false,
  ischeckingregisteredvendor: false,
  isTaskdatainlimit: {},
  isEditTemplatevalueModalvisible: false,
  editTemplatefiltereddata: [],
  isEditTemplatedataSubmiiting: false,
  EditTemplaterecord: {},
  isUpdateDocumentModalVisible: false,
  UpdateDocumentModalRecord: {},
  Errorlength: 0,
  isInviteVendorModalVisible: false,
  isCounterpartyCreated: false,
};
const taskManagementSlice = createSlice({
  name: "taskmanagement",
  initialState: initialState,
  reducers: {
    setrequestValue: (state, { payload }) => {
      let contentReq = {
        ...state.changeReqeditRecord,
        content: payload.content,
      };
      return {
        ...state,
        changeReqeditRecord: contentReq,
      };
    },
    setTaskModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
        state.currenttaskStep = 0;
        state.contentTabActivekey = "1";

        state.aciveTaskKey = "1";
      }
    },
    setEditContentModalVisible: (state, { payload }) => {
      state.editContentVisible = payload.visible;
      state.editContentType = payload.type;
      state.editContentDetail = payload.taskDetailsdata || {};
      if (!payload.visible) {
        state.editContentDetail = {};
        state.editContentLoading = false;
      }
    },
    setTemplatesData: (state, { payload }) => {
      let contentDetaildata = { ...state.editContentDetail };
      contentDetaildata.formWithValue = [payload.recData];
      return {
        ...state,
        editContentDetail: contentDetaildata,
      };
    },

    onChangeBulkEdit: (state, { payload }) => {
      state.bulk_work_flow_id = payload;
    },
    changeRequestDrawer: (state, { payload }) => {
      state.isChangeRequestDrawerVisible = payload.visible;
      state.taskDetailsdata = payload.taskDetailsdata;
      state.changeReqContentType = payload.type || "";
      state.changeReqeditRecord = payload.record || {};
      state.editRecordContent = payload.editRecordContent
        ? Array.isArray(payload.editRecordContent) &&
          payload.editRecordContent.length
          ? payload.editRecordContent[0].content
          : payload.editRecordContent.content
        : payload.record
        ? payload.record.content
        : "";
      if (!payload.visible) {
        state.editRecord = {};
        state.taskDetailsdata = {};
      }
    },
    setbulkEditTaskModalVisible: (state, { payload }) => {
      state.isbulkEditVisible = payload.visible;
      state.bulkRecord = { ...payload.record, is_edit: payload.visible } || {};
      if (!payload.visible) {
        state.bulkRecord = {};
      }
    },
    setSubmitBulkData: (state, { payload }) => {
      if (payload.record.excel_id) {
        let findBulkIndex = state.uploadedexceldata.findIndex(
          (ert) => ert.excel_id === payload.record.excel_id
        );
        if (findBulkIndex > -1) {
          let reqArray = [...state.uploadedexceldata];
          reqArray[findBulkIndex] = {
            ...payload.record,
            is_edit: payload.false,
          };
          state.uploadedexceldata = reqArray || [];
        }
      }
      state.isbulkEditVisible = false;
    },
    onChangeBulkEditData: (state, { payload }) => {
      state.bulkRecord = payload || {};
    },
    SetCommentmodal: (state, { payload }) => {
      state.iscommentmodalVisible = payload.visible;
      state.TaskcommentPagination = {
        ...state.TaskcommentPagination,
        taskId: payload?.record?._id,
      };
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
        state.currenttaskStep = 0;
        state.contentTabActivekey = "1";

        state.aciveTaskKey = "1";
      }
    },
    setbulkTaskModalVisible: (state, { payload }) => {
      state.isbulkcreateVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
        state.currenttaskStep = 0;
        state.contentTabActivekey = "1";

        state.aciveTaskKey = "1";
      }
    },
    updateContentData: (state, { payload }) => {
      if (Object.keys(state.addendumtaskdetailsdata).length) {
        let updateData = { ...state.addendumtaskdetailsdata };
        if (
          updateData &&
          updateData.content &&
          Array.isArray(updateData.content) &&
          updateData.content.length
        ) {
          updateData.content[0].content = payload.content.content;
        } else {
          updateData.content = [payload.content];
        }
        if (payload?.Templatedata) {
          updateData.Templatedata = payload?.Templatedata;
        }
        state.addendumtaskdetailsdata = updateData;
      }
      if (Object.keys(state.renewtaskdetailsdata).length) {
        let renewTask = { ...state.renewtaskdetailsdata };
        renewTask.content[0].content = payload.content.content;
        if (payload?.Templatedata) {
          renewTask.Templatedata = payload?.Templatedata;
        }
        state.renewtaskdetailsdata = renewTask;
      }
    },
    setcreateaddendumDrawer: (state, { payload }) => {
      state.iscreateaddendumdrawervisible = payload.visible;
      state.isaddendumtype = payload?.type;
      state.addendumtaskdetailsdata = payload?.record;
      if (payload.visible) {
        state.currentaddendumstep =
          payload?.record?.step_status > 6
            ? payload?.record?.step_status - 1
            : state.currentaddendumstep;
        state.addeddumTemplate_id = [];
        state.addeddumContent_id = [];
      }
      if (!payload.visible) {
        state.addendumtaskdetailsdata = {};
        state.currentaddendumstep = 0;
        state.addeddumTemplate_id = [];
        state.addeddumContent_id = [];
      }
    },
    setcurrentaddendumSteps: (state, { payload }) => {
      state.currentaddendumstep = payload;
      state.currentrenewstep = payload;
      state.addeddumTemplate_id = [];
      state.addeddumContent_id = [];
    },
    setValue: (state, { payload }) => {
      state[payload.key] = payload.value;
      if (payload.key === "is_reviewId" && payload.value) {
        let findName = state.RenewalStaffList.find(
          (eve) => eve._id === payload.value
        );
        if (Object.keys(findName).length > 0) {
          state.reviewerName = `${findName?.firstName} ${findName?.lastName}`;
        }
      }
      // state.currentrenewstep = payload;
    },
    setcreateaddendumModel: (state, { payload }) => {
      state.iscreateaddendumvisible = payload.visible;
      state.isaddendumtype = payload.type;
      state.editRecord = state.taskTicketDetails;
    },
    setcurrentRenewSteps: (state, { payload }) => {
      state.currentrenewstep = payload;
    },
    setcreateRenewModel: (state, { payload }) => {
      state.iscreateRenewVisible = payload.visible;
      state.isRenewtype = payload.type;
    },
    showChecklistModelinContent: (state, { payload }) => {
      state.isChecklistContentvisible = payload.visible;
    },
    setRenewDrawer: (state, { payload }) => {
      state.iscreaterenewDrawervisible = payload.visible;
      state.isRenewtype = payload?.type;
      state.renewtaskdetailsdata = payload?.record;
      if (payload.visible) {
        state.currentrenewstep =
          payload?.record?.step_status > 6
            ? payload?.record?.step_status - 1
            : state.currentrenewstep;
      }
      if (!payload.visible) {
        state.renewtaskdetailsdata = {};
        state.currentrenewstep = 0;
      }
    },
    setbulktaskviewDrawerVisible: (state, { payload }) => {
      state.isbulktaskviewdrawerVisible = payload.visible;
      state.uploadedexceldata = payload.record || {};
      state.uploadedexcelheaderdata = payload.headerdata || {};
      state.workflowidforbulktask = payload.workflowid || "";
      state.forcheckingarraydata = payload.dataforcheck || [];
      if (payload.visible) {
        state.isbulkcreateVisible = false;
      }
    },
    setDrawerVisible: (state, { payload }) => {
      state.isdrawerVisible = payload.visible;
      state.DrawerRecord = payload.record;
      state.currenttaskStep = 0;
      state.contentTabActivekey = "1";

      state.aciveTaskKey = "1";
      state.Templatefiltereddata = [];
      state.TaskcommentPagination = {
        ...state.TaskcommentPagination,
        taskId: payload?.record?._id,
      };
      if (payload?.visible && payload?.record?.taskStatus === 15) {
        state.currenttaskStep = 1;
      }
      if (!payload.visible) {
        state.taskTicketDetails = {};
        state.approvalsData = [];
        state.newcreatedtaskdata = {};
        state.is_collobrative_mode = false;
        state.contentTabActivekey = "1";
        state.DrawerRecord = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.taskPagination = {
        ...state.taskPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.taskPagination = {
        ...state.taskPagination,
        search: action.payload.search || "",
      };
    },
    onChangetaskStep: (state, { payload }) => {
      state.currenttaskStep = payload;
      state.contentTabActivekey = "1";
    },
    contentTab: (state, { payload }) => {
      state.contentTabActivekey = payload;
    },
    onChangetabKey: (state, { payload }) => {
      state.aciveTaskKey = payload;
    },
    onChangecollapsePanel: (state, { payload }) => {
      state.collapseDataIds = payload;
    },
    valueaddedonChange: (state, { payload }) => {
      const { parentindex, value, contractType, field_id } = payload;
      const updatedFields = [...state.Templatefiltereddata[parentindex].fields];
      let findChildIndex = updatedFields.findIndex(
        (eve) => eve._id === field_id
      );

      if (findChildIndex > -1) {
        updatedFields[findChildIndex] = {
          ...updatedFields[findChildIndex],
          value,
          contractType,
        };
      }
      const updatedTemplatefiltereddata = [...state.Templatefiltereddata];

      updatedTemplatefiltereddata[parentindex] = {
        ...updatedTemplatefiltereddata[parentindex],
        fields: updatedFields,
      };
      return {
        ...state,
        // contractExpiry: contractexpiry,
        Templatefiltereddata: updatedTemplatefiltereddata || [],
      };
    },
    addendumvalueaddedonChange: (state, { payload }) => {
      const { parentindex, value, contractType, field } = payload;
      const updatedFields = [...state.Addendumfiltereddata[parentindex].fields];
      let findChildIndex = updatedFields.findIndex(
        (eve) => eve._id === field._id
      );
      if (findChildIndex > -1) {
        updatedFields[findChildIndex] = {
          ...updatedFields[findChildIndex],
          value,
          contractType,
        };
      }
      const updatedTemplatefiltereddata = [...state.Addendumfiltereddata];
      updatedTemplatefiltereddata[parentindex] = {
        ...updatedTemplatefiltereddata[parentindex],
        fields: updatedFields,
      };
      return {
        ...state,
        // contractExpiry: contractexpiry,
        Addendumfiltereddata: updatedTemplatefiltereddata,
      };
    },
    CheclistvalueonChange: (state, { payload }) => {
      const { parentindex, childindex, value } = payload;
      const updatedFields = [
        ...state.Checklistfiltereddata[parentindex].checkListData,
      ];
      updatedFields[childindex] = {
        ...updatedFields[childindex],
        value,
      };
      const updatedChecklistfiltereddata = [...state.Checklistfiltereddata];
      updatedChecklistfiltereddata[parentindex] = {
        ...updatedChecklistfiltereddata[parentindex],
        checkListData: updatedFields,
      };
      return {
        ...state,
        Checklistfiltereddata: updatedChecklistfiltereddata,
      };
    },
    approvalDataValues: (state, { payload }) => {
      const { index, value, key } = payload;
      const updatedFields = {
        ...state.approvalsData[index],
        [key]: [...value],
      };
      const updatedChecklistfiltereddata = [...state.approvalsData];
      updatedChecklistfiltereddata[index] = updatedFields;
      return {
        ...state,
        approvalsData: updatedChecklistfiltereddata,
      };
    },
    clearVendorData: (state, { payload }) => {
      let sfindIndex = state.approvalsData.findIndex(
        (eve) => eve._id === payload._id
      );
      if (sfindIndex > -1) {
        const updatedFields = {
          ...state.approvalsData[sfindIndex],
          vendor_ids: [],
          guest_user_email_id: undefined,
          guest_action_ids: [],
        };
        const updatedChecklistfiltereddata = [...state.approvalsData];
        updatedChecklistfiltereddata[sfindIndex] = updatedFields;
        return {
          ...state,
          approvalsData: updatedChecklistfiltereddata,
        };
      }
    },
    approvalDataValues1: (state, { payload }) => {
      const { index, value, key, is_guest_user } = payload;
      const updatedFields = {
        ...state.approvalsData[index],
        [key]: value,
        is_guest_user: is_guest_user,
      };
      const updatedChecklistfiltereddata = [...state.approvalsData];
      updatedChecklistfiltereddata[index] = updatedFields;
      return {
        ...state,
        approvalsData: updatedChecklistfiltereddata,
      };
    },

    setEditCommentData: (state, { payload }) => {
      state.iscoommentmodalVisible = payload.visible;
      state.editcommentdata = payload.record || {};
    },
    addVendorEmailIds: (state, { payload }) => {
      let recData = {
        // email:payload,
        firstName: payload.name,
        vendorId: payload.name,
        is_email_id: true,
        is_guest_user: payload.is_guest_user,
      };
      let strData = [...state.vendorTableData];
      strData.unshift(recData);
      state.vendorTableData = strData;
    },
    setGuestUserData: (state, { payload }) => {
      // const { index } = payload;

      let sfindIndex = state.approvalsData.findIndex(
        (eve) => eve._id === payload._id
      );
      if (sfindIndex > -1) {
        const updatedFields = {
          ...state.approvalsData[sfindIndex],
          guest_user_email_id: payload.guest_user_email_id,
          guest_action_ids: payload.guest_action_ids,
        };
        const updatedChecklistfiltereddata = [...state.approvalsData];
        updatedChecklistfiltereddata[sfindIndex] = updatedFields;
        return {
          ...state,
          approvalsData: updatedChecklistfiltereddata,
        };
      }
    },
    taskmanagementdatareset: (state, action) => {
      return initialState;
    },
    setapprovalmodal: (state, { payload }) => {
      state.isapprovalmodalVisible = payload.visible;
      state.editcommentdata = payload.record || {};
    },
    setGuestUserModal: (state, { payload }) => {
      state.isGuestUserModal = payload.visible;
      state.guestUserDetail = payload.record || {};
    },
    getuserSelectdata: (state, { payload }) => {
      state.isuserSelectVisible = payload.visible;
      state.userselecteddata = payload.record || {};
    },
    setrequestchangeModal: (state, { payload }) => {
      state.isrequestchangeVisible = payload.visible;
      state.userselecteddata = payload.record || {};
    },
    setcustomconfirmModalVisible: (state, { payload }) => {
      state.iscustomconfirmvisible = payload.visible;
      state.iscustomconfirmdata = payload.record || {};
    },
    setEmailPopupVisible: (state, { payload }) => {
      state.isEmailPopupvisible = payload.visible;
      state.emailRecord = payload.record || {};
    },
    Setcontractpreviewmodal: (state, { payload }) => {
      state.isContractpreviewPreviewVisible = payload.visible;
    },
    setReviewMorePopupVisible: (state, { payload }) => {
      state.isReviewMorePopupvisible = payload.visible;
      state.reviewMoreRecord = payload.record || {};
    },
    obligationDrawerVisible: (state, { payload }) => {
      state.isObligationDrawerVisible = payload.visible;
      state.obligationRecord = payload.record || {};
    },
    versionDrawerVisiblefunc: (state, { payload }) => {
      state.versionDrawerVisible = payload.visible;
      state.versionRecord = payload.record || {};
    },
    complateReviewPopup: (state, { payload }) => {
      state.iscompleteReviewPopup = payload.visible;
      state.reviewRecord = payload.record || {};
    },
    SetNoncollabchangeRequestDrawer: (state, { payload }) => {
      state.isNoncollabRequestDrawerVisible = payload.visible;
      state.taskDetailsdata = payload.taskDetailsdata;
      state.changeReqContentType = payload?.type || "kl";
      state.changeReqeditRecord = payload.record || {};
      state.editRecordContent = payload.editRecordContent
        ? Array.isArray(payload.editRecordContent) &&
          payload.editRecordContent.length
          ? payload.editRecordContent[0].content
          : payload.editRecordContent.content
        : payload.record
        ? payload.record.content
        : "";
      if (!payload.visible) {
        state.editRecord = {};
        state.taskDetailsdata = {};
      }
    },
    setVersionDrawerVisible: (state, { payload }) => {
      state.isVersiondrawerVisible = payload.visible;
      
      state.TaskcommentPagination = {
        ...state.TaskcommentPagination,
        taskId: payload?.record?._id,
      };
      
      if (payload.visible) {
        state.DrawerRecord = payload.record;
        // state.DrawerRecord = {};
        // state.isVersiondrawerVisible = false;
        // state.versionList = [];
        // state.taskDetailsdata = {};
      }
    },
    setisviewObligationdrawerVisible: (state, { payload }) => {
      state.isviewObligationdrawerVisible = payload.visible;
      state.DrawerRecord = payload?.record || {};

      if (!payload.visible) {
        state.DrawerRecord = {};
        state.isviewObligationdrawerVisible = false;
        state.taskDetailsdata = {};
      }
    },
    setEditTemplatevalueModalvisible: (state, { payload }) => {
      state.isEditTemplatevalueModalvisible = payload.visible;
      state.EditTemplaterecord = payload.record;

      if (!payload.visible) {
        state.isEditTemplatevalueModalvisible = false;
        state.EditTemplaterecord = {};
      }
    },
    templateeditvalueaddedonChange: (state, { payload }) => {
      const { parentindex, value, contractType, childindex } = payload;

      const updatedFields = [...state.Templatefiltereddata[parentindex].fields];

      if (childindex) {
        updatedFields[childindex] = {
          ...updatedFields[childindex],
          value,
          contractType,
        };
      }
      const updatedTemplatefiltereddata = [...state.Templatefiltereddata];

      updatedTemplatefiltereddata[parentindex] = {
        ...updatedTemplatefiltereddata[parentindex],
        fields: updatedFields,
      };
      return {
        ...state,
        // contractExpiry: contractexpiry,
        editTemplatefiltereddata: updatedTemplatefiltereddata || [],
      };
    },
    setUpdateDocumentModalVisible: (state, { payload }) => {
      state.isUpdateDocumentModalVisible = payload?.visible;
      state.UpdateDocumentModalRecord = payload?.record;
      if (!payload.visible) {
      }
    },
    setEditinfoModalvisible: (state, { payload }) => {
      if (!payload.visible) {
      }
    },
    setErrorlength: (state, { payload = 0 }) => {
      state.Errorlength = payload;
      if (!payload) {
      }
    },
    setInviteVendorModalVisible: (state, { payload = {} }) => {
      state.isInviteVendorModalVisible = payload?.visible;
      state.isCounterpartyCreated = payload?.CounterpartyCreated || false;
      if (!payload.visible) {
      }
    },
    setisCounterpartyCreated: (state, { payload = {} }) => {
      state.isCounterpartyCreated = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskdetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTaskdetails.fulfilled, (state, { payload }) => {
        state.taskTableData = payload.data;
        state.isTaskdatainlimit = payload?.licenseQuota?.task;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.taskPagination = {
          ...state.taskPagination,
          total: payload.total,
        };
      })
      .addCase(getTaskdetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createTask.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createTask.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        state.is_reviewCountEdit = false;
        state.is_reviewId = null;
        state.is_guest_user = false;
        if (payload.STATUS_CODE === 201) {
          state.newcreatedtaskdata = payload.data;
        }
      })
      .addCase(createTask.rejected, (state) => {
        state.isCreateLoading = false;
      })
      .addCase(deleteTask.pending, (state, { payload }) => {
        //conditions
        // responseMessageHandler()
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deleteTask.rejected, (state, { payload }) => {
        //conditions
      })
      .addCase(getTaskcomments.pending, (state, { payload }) => {
        state.commentLoading = true;
      })
      .addCase(getTaskcomments.fulfilled, (state, { payload }) => {
        state.commentLoading = false;
        state.TaskCommentData = payload.data;
      })
      .addCase(getTaskcomments.rejected, (state, { payload }) => {
        state.commentLoading = false;
      })
      .addCase(createTaskcomment.pending, (state, { payload }) => {
        state.iscommentLoading = true;
      })
      .addCase(createTaskcomment.fulfilled, (state, { payload }) => {
        state.iscommentSuccess = true;
        state.iscommentLoading = false;
        state.iscoommentmodalVisible = false;
      })
      .addCase(createTaskcomment.rejected, (state, { payload }) => {
        state.iscommentLoading = false;
      })
      .addCase(getDetails.pending, (state) => {
        state.isDataLoading = true;
        state.taskTicketDetails = {};
      })
      .addCase(getDetails.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data && payload.data.templateMainDetail) {
          state.Templatefiltereddata = [payload.data.templateMainDetail] || [];
        }
        if (
          payload.data &&
          payload.data &&
          payload.data.checklistWithValue.length > 0
        ) {
          state.Checklistfiltereddata = payload.data.checklistWithValue;
        } else {
          state.Checklistfiltereddata = payload.data.checklistDetails;
        }
        if (payload.data && payload.data && payload.data.approvals.length > 0) {
          state.approvalsData = payload.data.approvals;
        } else {
          state.approvalsData = payload.data.approvals;
        }
        state.taskTicketDetails = payload.data;
        state.DrawerRecord = payload.data;
        state.is_collobrative_mode = payload.data?.is_collobrative_mode;

        if (state.isChangeRequestDrawerVisible) {
          state.taskDetailsdata = payload.data;
          state.changeReqContentType = "edittaskcontent";
          state.changeReqeditRecord = payload.data?.contentMainDetail;
          state.editRecordContent = payload.data?.contentMainDetail?.content;
        }
        // if (payload.data?.content[0]) {
        //   state.taskDetailsdata = payload.data;
        //   state.changeReqeditRecord = payload.data?.content[0];
        // }
        state.isDataLoading = false;
        state.contractExpiry = payload?.data?.contractExpiry;
      })
      .addCase(getDetails.rejected, (state, { payload }) => {
        state.isDataLoading = false;
      })
      .addCase(createTemplateTask.pending, (state) => {
        state.isTaskSubmitLoading = true;
      })
      .addCase(createTemplateTask.fulfilled, (state, { payload }) => {
        state.isTaskSubmitLoading = false;
        if (state.currenttaskStep === 1) {
          state.currenttaskStep = 0;
          state.aciveTaskKey = "1";
          state.contentTabActivekey = "1";

          state.isdrawerVisible = false;
        }
      })
      .addCase(createTemplateTask.rejected, (state, { payload }) => {
        state.isTaskSubmitLoading = false;
      })
      .addCase(taskAssignee.pending, (state) => {
        state.isTaskAssigneeLoading = true;
        state.isTaskSubmitLoading = true;
      })
      .addCase(taskAssignee.fulfilled, (state, { payload }) => {
        state.isTaskAssigneeLoading = false;
        state.isTaskSubmitLoading = false;
      })
      .addCase(taskAssignee.rejected, (state, { payload }) => {
        state.isTaskAssigneeLoading = false;
        state.isTaskSubmitLoading = false;
      })
      .addCase(taskConnect.pending, (state) => {
        state.isTaskConnectLoading = true;
      })
      .addCase(taskConnect.fulfilled, (state, { payload }) => {
        state.taskContentString = payload.data.template;
        state.isTaskConnectLoading = false;
      })
      .addCase(taskConnect.rejected, (state, { payload }) => {
        state.isTaskConnectLoading = false;
      })
      .addCase(getVendordetailsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendordetailsList.fulfilled, (state, { payload }) => {
        state.vendorTableData = payload.data;
        state.isLoading = false;
        // state.totalCount = payload.totalCount;
        // state.vendorPagination = {
        //   ...state.vendorPagination,
        //   total: payload.total,
        // };
      })
      .addCase(getVendordetailsList.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(getStaffList.pending, (state) => {
        state.isgetStaffListLoading = true;
      })
      .addCase(getStaffList.fulfilled, (state, { payload }) => {
        state.staffLists = payload.data;
        state.isgetStaffListLoading = false;
      })
      .addCase(getStaffList.rejected, (state, { payload }) => {
        state.isgetStaffListLoading = false;
      })
      .addCase(getWorkflowdetailsfortask.pending, (state) => {
        // state.workflowdata = true;
      })
      .addCase(getWorkflowdetailsfortask.fulfilled, (state, { payload }) => {
        state.workflowData = payload.data;
      })
      .addCase(getWorkflowdetailsfortask.rejected, (state, { payload }) => {})
      .addCase(createbulktask.rejected, (state, { payload }) => {
        // state.workflowdata = true;

        state.isbulktaskviewdrawerVisible = true;
        state.forcheckingarraydata = [];
        state.uploadedexceldata = {};
        state.uploadedexcelheaderdata = {};
        state.workflowidforbulktask = "";

        state.forcheckingarraydata =
          ["task_name", "task_start_date", "task_end_date"] || [];
        state.bulkModaltype = "bulkEdit";
        if (payload.data && payload.data.length) {
          let recData = payload.data.map((ert) => {
            return {
              ...ert,
              is_edit: false,
            };
          });
          state.uploadedexceldata = recData || [];
        }
        state.uploadedexcelheaderdata = Object.keys(payload.data[0]) || {};
        // state.uploadedexcelheaderdata = payload.headerdata || {};
        // state.workflowidforbulktask = payload.workflowid || "";
      })
      .addCase(createbulktask.pending, (state, { payload }) => {
        // state.isbulktaskviewdrawerVisible = false;
      })
      .addCase(createbulktask.fulfilled, (state, { payload }) => {
        state.isbulktaskviewdrawerVisible = false;
      })

      .addCase(changeforrequestAction.pending, (state) => {
        state.ischangeforrequestLoading = true;
      })
      .addCase(changeforrequestAction.fulfilled, (state, { payload }) => {
        state.ischangeforrequestLoading = false;
        state.isrequestchangeVisible = false;
      })
      .addCase(changeforrequestAction.rejected, (state, { payload }) => {
        state.ischangeforrequestLoading = false;
      })
      .addCase(editTaskContentTemplate.pending, (state) => {
        state.ischangeforrequestLoading = true;
      })
      .addCase(editTaskContentTemplate.fulfilled, (state, { payload }) => {
        state.isChangeRequestDrawerVisible = false;
        state.taskDetailsdata = {};
        state.changeReqContentType = "";
        state.changeReqeditRecord = {};
      })
      .addCase(editTaskContentTemplate.rejected, (state, { payload }) => {
        state.ischangeforrequestLoading = false;
      })
      .addCase(getTaskactivitylist.pending, (state, { payload }) => {})
      .addCase(getTaskactivitylist.fulfilled, (state, { payload }) => {
        state.taskactivityData = payload.data;
      })
      .addCase(getTaskactivitylist.rejected, (state, { payload }) => {})
      .addCase(createTaskaddendum.pending, (state, { payload }) => {
        state.isaddendumCreating = true;
      })
      .addCase(createTaskaddendum.fulfilled, (state, { payload }) => {
        state.iscreateaddendumdrawervisible = true;
        state.iscreateaddendumvisible = false;
        state.addendumcreationdata = payload.data;
        state.addendumtaskdetailsdata = payload.data;
        state.isaddendumCreating = false;
      })
      .addCase(createTaskaddendum.rejected, (state, { payload }) => {
        state.isaddendumCreating = false;
      })
      .addCase(Taskaddendumstep.pending, (state, { payload }) => {
        state.taskaddendumcreateddataloading = true;
      })
      .addCase(Taskaddendumstep.fulfilled, (state, { payload }) => {
        state.taskaddendumcreateddata = payload.data;
        state.taskaddendumcreateddataloading = false;
        state.currentaddendumstep = state.currentaddendumstep + 1;
        state.addeddumTemplate_id = [];
        state.addeddumContent_id = [];
        if (state.currentaddendumstep === 7) {
          state.iscreateaddendumdrawervisible = false;
          state.isaddendumtype = "";
          state.addendumtaskdetailsdata = {};
          state.currentaddendumstep = 0;
        }
      })
      .addCase(Taskaddendumstep.rejected, (state, { payload }) => {
        state.taskaddendumcreateddataloading = false;
      })
      .addCase(getaddendumsteplistanddetails.pending, (state, { payload }) => {
        state.isAddendumTableDataLoading = true;
      })
      .addCase(
        getaddendumsteplistanddetails.fulfilled,
        (state, { payload }) => {
          state.Taskaddendumsteplist = payload.data;
          state.isAddendumTableDataLoading = false;
        }
      )
      .addCase(getaddendumsteplistanddetails.rejected, (state, { payload }) => {
        state.isAddendumTableDataLoading = false;
      })
      .addCase(getaddendumstepdetails.pending, (state, { payload }) => {})
      .addCase(getaddendumstepdetails.fulfilled, (state, { payload }) => {
        state.addendumtaskdetailsdata = payload.data;
      })
      .addCase(getaddendumstepdetails.rejected, (state, { payload }) => {})
      .addCase(gettaskaddendumstepdetails.pending, (state, { payload }) => {})
      .addCase(gettaskaddendumstepdetails.fulfilled, (state, { payload }) => {
        if (payload.data && Object.keys(payload.data).length) {
          state.addendumtaskdetailsdata = payload.data;
          if (
            payload.data &&
            payload.data &&
            payload.data.formWithValue &&
            payload.data.formWithValue.length > 0
          ) {
            state.Addendumfiltereddata = payload.data.formWithValue;
          } else {
            state.Addendumfiltereddata = payload.data.templateDetails;
          }
          // if (
          //   state.Templatefiltereddata &&
          //   state.Templatefiltereddata.length &&
          //   state.Addendumfiltereddata[0] &&
          //   state.Addendumfiltereddata[0].fields &&
          //   state.Addendumfiltereddata[0].fields.length
          // ) {
          //   state.Addendumfiltereddata[0].fields[0].value =
          //     state.Templatefiltereddata[0].fields[0].value;
          //   state.Addendumfiltereddata[0].fields[1].value =
          //     state.Templatefiltereddata[0].fields[1].value;
          //   state.Addendumfiltereddata[0].fields[1].contractType =
          //     state.Templatefiltereddata[0].fields[1].contractType;
          // }
        }

        // state.Addendumfiltereddata[1].fields[0].value = state.Templatefiltereddata[1].fields[0].value;
        // state.Addendumfiltereddata[1].fields[1].value = state.Templatefiltereddata[1].fields[1].value;
      })
      .addCase(gettaskaddendumstepdetails.rejected, (state, { payload }) => {})
      ///////////////////////////////Renew//////////////////////
      .addCase(createTaskRenew.pending, (state, { payload }) => {
        state.isRenewCreating = true;
      })
      .addCase(createTaskRenew.fulfilled, (state, { payload }) => {
        state.iscreaterenewDrawervisible = true;
        state.iscreateRenewVisible = false;
        state.renewtaskdetailsdata = payload.data;
        state.oneRenewtaskdetailsdata = payload.data;
        state.isRenewCreating = false;
        state.currentrenewstep = 0;
      })
      .addCase(createTaskRenew.rejected, (state, { payload }) => {
        state.isRenewCreating = false;
      })
      .addCase(getRenewsteplistanddetails.pending, (state, { payload }) => {
        state.isRenewTableDataLoading = true;
      })
      .addCase(getRenewsteplistanddetails.fulfilled, (state, { payload }) => {
        state.TaskRenewsteplist = payload.data;
        if (payload.data && Object.keys(payload.data).length) {
          state.oneaddendumtaskdetailsdata = payload.data;
          if (
            payload.data &&
            payload.data &&
            payload.data.formWithValue &&
            payload.data.formWithValue.length > 0
          ) {
            state.Addendumfiltereddata = payload.data.formWithValue;
          } else {
            state.Addendumfiltereddata = payload.data.templateDetails;
          }
          // if (
          //   state.Templatefiltereddata &&
          //   state.Templatefiltereddata.length &&
          //   state.Addendumfiltereddata[0] &&
          //   state.Addendumfiltereddata[0].fields &&
          //   state.Addendumfiltereddata[0].fields.length
          // ) {
          //   state.Addendumfiltereddata[0].fields[0].value =
          //     state.Templatefiltereddata[0].fields[0].value;
          //   state.Addendumfiltereddata[0].fields[1].value =
          //     state.Templatefiltereddata[0].fields[1].value;
          //   state.Addendumfiltereddata[0].fields[1].contractType =
          //     state.Templatefiltereddata[0].fields[1].contractType;
          // }
        }

        state.isRenewTableDataLoading = false;
      })
      .addCase(getRenewsteplistanddetails.rejected, (state, { payload }) => {
        state.isRenewTableDataLoading = false;
      })

      .addCase(getReviewerStaffList.pending, (state, { payload }) => {
        state.isRenewListLoading = true;
      })
      .addCase(getReviewerStaffList.fulfilled, (state, { payload }) => {
        let staffList = payload.data.map((item, index) => {
          return {
            ...item,
            title:
              item._id === "select_all" || (item.users && item.users.length) ? (
                <span key={item._id}>{item.deptName}</span>
              ) : (
                <span key={item._id}>
                  {item.deptName}
                  <b>{" (No Staff)"}</b>
                </span>
              ),
            value: item._id,
            key: item._id,
            disabled: item?.users?.length === 0,
            children: item.users.map((cItem, cIndex) => {
              return {
                ...cItem,
                title: cItem.firstName + " " + cItem.lastName,
                value: cItem._id,
                key: cItem._id,
              };
            }),
          };
        });
        state.RenewalStaffList = staffList;
        // this.scaAssignStafList = [
        //   { title: "Select All", value: "select_all", key: "select_all", children: staffList },
        // ];

        state.isRenewListLoading = false;
      })
      .addCase(getReviewerStaffList.rejected, (state, { payload }) => {
        state.isRenewListLoading = false;
      })
      .addCase(ReviewerSubmit.pending, (state, { payload }) => {
        state.isRenewListLoading = true;
      })
      .addCase(ReviewerSubmit.fulfilled, (state, { payload }) => {
        // state.RenewalStaffList = payload.data;
        state.isRenewListLoading = false;
      })
      .addCase(ReviewerSubmit.rejected, (state, { payload }) => {
        state.isRenewListLoading = false;
      })
      .addCase(customActions.pending, (state, { payload }) => {
        state.isRenewListLoading = true;
        state.addeddumTemplate_id = [];
        state.addeddumContent_id = [];
      })
      .addCase(customActions.fulfilled, (state, { payload }) => {
        if (
          state.iscreateaddendumdrawervisible ||
          state.iscreaterenewDrawervisible
        ) {
          state.addeddumTemplate_id = [payload._id];
          state.addeddumContent_id = [payload._id];
        }
      })
      .addCase(customActions.rejected, (state, { payload }) => {
        state.isRenewListLoading = false;
        state.addeddumTemplate_id = [];
        state.addeddumContent_id = [];
      })

      .addCase(TaskRenewstep.pending, (state, { payload }) => {
        state.taskRenewcreateddataloading = true;
      })
      .addCase(TaskRenewstep.fulfilled, (state, { payload }) => {
        state.taskRenewcreateddataloading = false;
        state.currentrenewstep = state.currentrenewstep + 1;
        if (state.currentrenewstep === 7) {
          state.isdrawerVisible = false;
          state.contentTabActivekey = "1";

          state.iscreaterenewDrawervisible = false;
          state.isRenewtype = "";
          state.renewtaskdetailsdata = {};
          state.currentrenewstep = 0;
        }
      })
      .addCase(TaskRenewstep.rejected, (state, { payload }) => {
        state.taskRenewcreateddataloading = false;
      })

      .addCase(checklistDataGet.pending, (state, { payload }) => {
        state.checklistDataLoading = true;
      })
      .addCase(checklistDataGet.fulfilled, (state, { payload }) => {
        state.checklistDataLoading = false;
        state.checlistPreviewData = payload.data;
      })
      .addCase(checklistDataGet.rejected, (state, { payload }) => {
        state.checklistDataLoading = false;
      })

      .addCase(getVerionList.pending, (state, { payload }) => {
        state.versionLoading = true;
      })
      .addCase(getVerionList.fulfilled, (state, { payload }) => {
        state.versionList = payload.data;
        state.versionLoading = false;
      })
      .addCase(getVerionList.rejected, (state, { payload }) => {
        state.versionLoading = false;
      })

      .addCase(saveVersion.pending, (state, { payload }) => {
        state.isversionsaving = true;
      })
      .addCase(saveVersion.fulfilled, (state, { payload }) => {
        // state.RenewalStaffList = payload.data;
        state.isversionsaving = false;
        state.currenttaskStep = 0;
        state.aciveTaskKey = "1";
        state.contentTabActivekey = "1";
      })
      .addCase(saveVersion.rejected, (state, { payload }) => {
        state.isversionsaving = false;
      })

      .addCase(getCommentContent.pending, (state, { payload }) => {
        state.commentReqLoading = true;
      })
      .addCase(getCommentContent.fulfilled, (state, { payload }) => {
        state.commentReqLoading = false;
        state.commentReqData = payload.data;
      })
      .addCase(getCommentContent.rejected, (state, { payload }) => {
        state.commentReqLoading = false;
      })

      //getReviewerEmailIds
      .addCase(getReviewerEmailIds.pending, (state, { payload }) => {
        state.reviewerEmailLoading = true;
      })
      .addCase(getReviewerEmailIds.fulfilled, (state, { payload }) => {
        state.reviewerEmailLoading = false;
        state.reviewerEmailObject = payload.data;
        // state.reviewerEmailIds = payload.data;
      })
      .addCase(getReviewerEmailIds.rejected, (state, { payload }) => {
        state.reviewerEmailLoading = false;
      })
      //sendReviewerInvitaion
      .addCase(sendReviewerInvitaion.pending, (state, { payload }) => {
        state.reviewerInvitationLoading = true;
      })
      .addCase(sendReviewerInvitaion.fulfilled, (state, { payload }) => {
        state.reviewerInvitationLoading = false;
        // state.isdrawerVisible = false;
        state.isEmailPopupvisible = false;
        // state.taskTicketDetails = {};
        // state.approvalsData = [];
        // state.newcreatedtaskdata = {};
        // state.reviewerEmailIds = payload.data;
      })
      .addCase(sendReviewerInvitaion.rejected, (state, { payload }) => {
        state.reviewerInvitationLoading = false;
      })
      .addCase(sendMailToParties.pending, (state, { payload }) => {
        state.reviewerInvitationLoading = true;
      })
      .addCase(sendMailToParties.fulfilled, (state, { payload }) => {
        state.reviewerInvitationLoading = false;
        // state.isdrawerVisible = false;
        state.isEmailPopupvisible = false;
        // state.taskTicketDetails = {};
        // state.approvalsData = [];
        // state.newcreatedtaskdata = {};
        // state.reviewerEmailIds = payload.data;
      })
      .addCase(sendMailToParties.rejected, (state, { payload }) => {
        state.reviewerInvitationLoading = false;
      })
      //ComplteReviewerInvitaion
      .addCase(completeReviewerInvitaion.pending, (state, { payload }) => {
        state.completeReviewLoading = true;
      })
      .addCase(completeReviewerInvitaion.fulfilled, (state, { payload }) => {
        state.completeReviewLoading = false;
        state.iscompleteReviewPopup = false;
        state.reviewerInvitationLoading = false;
        // state.isdrawerVisible = false;
        // state.taskTicketDetails = {};
        // state.approvalsData = [];
        // state.reviewRecord = {};
      })
      .addCase(completeReviewerInvitaion.rejected, (state, { payload }) => {
        state.completeReviewLoading = false;
      })
      //getReviewDetails
      .addCase(getReviewDetails.pending, (state, { payload }) => {
        state.reviewerDetailsLoading = true;
        state.reviewDetails = [];
      })
      .addCase(getReviewDetails.fulfilled, (state, { payload }) => {
        state.reviewerDetailsLoading = false;
        state.reviewDetails = payload.data;
      })
      .addCase(getReviewDetails.rejected, (state, { payload }) => {
        state.reviewerDetailsLoading = false;
      })
      //completeReviewerParties

      .addCase(completeReviewerParties.pending, (state, { payload }) => {
        state.completeReviewLoading = true;
      })
      .addCase(completeReviewerParties.fulfilled, (state, { payload }) => {
        state.completeReviewLoading = false;
        state.iscompleteReviewPopup = false;
        state.reviewerInvitationLoading = false;
        // state.isdrawerVisible = false;
        // state.taskTicketDetails = {};
        // state.approvalsData = [];
        // state.reviewRecord = {};
      })
      .addCase(completeReviewerParties.rejected, (state, { payload }) => {
        state.completeReviewLoading = false;
      })
      .addCase(editTaskContentForNoncollab.pending, (state) => {
        state.ischangeforrequestLoading = true;
      })
      .addCase(editTaskContentForNoncollab.fulfilled, (state, { payload }) => {
        state.taskDetailsdata = {};
        state.changeReqContentType = "";
        state.changeReqeditRecord = {};
        state.isChangeRequestDrawerVisible = false;
        state.ischangeforrequestLoading = false;
      })
      .addCase(editTaskContentForNoncollab.rejected, (state, { payload }) => {
        state.ischangeforrequestLoading = false;
      })
      .addCase(editTaskTemplateForNoncollab.pending, (state) => {})
      .addCase(
        editTaskTemplateForNoncollab.fulfilled,
        (state, { payload }) => {}
      )
      .addCase(
        editTaskTemplateForNoncollab.rejected,
        (state, { payload }) => {}
      )
      .addCase(checkEmailregisteredorNot.pending, (state) => {
        state.ischeckingregisteredvendor = true;
      })
      .addCase(checkEmailregisteredorNot.fulfilled, (state, { payload }) => {
        state.ischeckingregisteredvendor = false;
      })
      .addCase(checkEmailregisteredorNot.rejected, (state, { payload }) => {
        state.ischeckingregisteredvendor = false;
      })
      .addCase(setAbortTask.pending, (state) => {
        state.isDataLoading = true;
      })
      .addCase(setAbortTask.fulfilled, (state, { payload }) => {
        state.isDataLoading = false;
      })
      .addCase(setAbortTask.rejected, (state, { payload }) => {
        state.isDataLoading = false;
      })
      .addCase(getreOpentask.pending, (state) => {
        state.isDataLoading = true;
      })
      .addCase(getreOpentask.fulfilled, (state, { payload }) => {
        state.isDataLoading = false;
      })
      .addCase(getreOpentask.rejected, (state, { payload }) => {
        state.isDataLoading = false;
      })
      .addCase(SubmiteditTemplateValues.pending, (state) => {
        state.isEditTemplatedataSubmiiting = true;
      })
      .addCase(SubmiteditTemplateValues.fulfilled, (state, { payload }) => {
        state.isEditTemplatedataSubmiiting = false;
        state.isEditTemplatevalueModalvisible = false;
        state.taskTicketDetails = payload?.data;
        state.taskDetailsdata = payload?.data;
        if (payload.data && payload.data && payload.data.templateMainDetail) {
          state.Templatefiltereddata = [payload.data.templateMainDetail] || [];
        }
      })
      .addCase(SubmiteditTemplateValues.rejected, (state, { payload }) => {
        state.isEditTemplatedataSubmiiting = false;
      });
  },
});
export const {
  setTaskModalVisible,
  SetCommentmodal,
  onPageChangesFilter,
  onSearchList,
  setDrawerVisible,
  valueaddedonChange,
  onChangetaskStep,
  CheclistvalueonChange,
  setEditCommentData,
  approvalDataValues,
  onChangecollapsePanel,
  taskmanagementdatareset,
  addVendorEmailIds,
  setapprovalmodal,
  getuserSelectdata,
  setValue,
  approvalDataValues1,
  setbulkTaskModalVisible,
  setbulkEditTaskModalVisible,
  setbulktaskviewDrawerVisible,
  onChangeBulkEdit,
  onChangeBulkEditData,
  setSubmitBulkData,
  setrequestchangeModal,
  setcustomconfirmModalVisible,
  setcreateaddendumDrawer,
  setcurrentaddendumSteps,
  setcreateaddendumModel,
  addendumvalueaddedonChange,
  setcreateRenewModel,
  setRenewDrawer,
  updateContentData,
  setcurrentRenewSteps,
  changeRequestDrawer,
  onChangetabKey,
  setEditContentModalVisible,
  setTemplatesData,
  Setcontractpreviewmodal,
  setrequestValue,
  setGuestUserModal,
  setGuestUserData,
  clearVendorData,
  contentTab,
  showChecklistModelinContent,
  setEmailPopupVisible,
  setReviewMorePopupVisible,
  obligationDrawerVisible,
  versionDrawerVisiblefunc,
  complateReviewPopup,
  SetNoncollabchangeRequestDrawer,
  setVersionDrawerVisible,
  setisviewObligationdrawerVisible,
  setEditTemplatevalueModalvisible,
  templateeditvalueaddedonChange,
  setUpdateDocumentModalVisible,
  setEditinfoModalvisible,
  setErrorlength,
  setInviteVendorModalVisible,
  setisCounterpartyCreated,
} = taskManagementSlice.actions;

export default taskManagementSlice.reducer;
